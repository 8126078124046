@media screen and (max-width:500px) {
    .my_navbar {
        padding: 17px 0;
    }

    .navbar .navbar-brand img {
        /* width: 135px; */
        width: 100px;
    }

    #hero_section {
        background-color: var(--darkPurple) !important;
        background: url('../Images/hero_bg_vertical.svg');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100%;
        padding: 50px 0 132px 0;
    }

    #hero_section .wrapper {
        background-image: none;
    }

    #hero_section .hero_div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        /* background-color: var(--darkPurple); */
        /* background: url('../Images/hero_bg_vertical.svg'); */
        padding: 0;
        height: auto;
    }

    #hero_section .hero_div .hero_left {
        width: 100%;
        align-items: center;
        /* padding-bottom: 0; */
    }

    #hero_section .hero_div .hero_right {
        width: 100%;
    }

    #hero_section .hero_div .hero_left .hero_para_1 {
        max-width: 241px;
        font-size: 20px;
        line-height: 118%;
    }

    #hero_section .hero_div .hero_left .hero_para_2 {
        max-width: 290px;
        font-size: 52px;
        line-height: 54px;
        height: 100%;
    }

    #hero_section .hero_div img {
        /* width: 30%; */
        display: none;
    }

    #hero_description .description_para {
        font-size: 16px;
        line-height: 27px;
        padding: 37.5px 5px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    #started_section {
        padding: 51px 0;
    }

    #started_section .started_row_1 {
        padding-bottom: 56px;
    }

    #started_section .started_heading {
        font-size: 36px;
        line-height: 32.03px;
        padding: 15px 0;
    }

    #started_section .main_card_1 {
        min-width: 190px;
    }

    .my_card_1 {
        max-width: 262.65px;
        height: 184.17px;
    }

    .my_card_1 span {
        width: 56.85px;
        height: 56.85px;
        top: -30px;
    }

    .my_card_1 span img {
        width: 26px;
    }

    .my_card_1 p {
        font-size: 22px;
        line-height: 30.63px;
    }


    #caregiver_hero_section .care_hero_main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 51px 0;
        height: auto;
    }

    #caregiver_hero_section .care_hero_main .care_para_2 {
        /* order: 2; */
        font-size: 52px;
        line-height: 54px;
    }

    #caregiver_hero_section .care_hero_main .care_para_1 {
        /* order: 1; */
        font-size: 20px;
        line-height: 118%;
    }

    #caregiver_hero_section .care_hero_main .care_hero_img {
        order: 3;
        position: static;
        max-width: 141px;
    }

    #care_description .description_para {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 16px;
        line-height: 27px;
        padding: 37.5px 5px;
        margin: 0 auto;
    }

    #care_description .description_para p {
        font-size: 16px;
        line-height: 27px;
    }

    #care_card_section {
        padding: 51px 0;
    }

    #care_card_section .care_card.card_3 {
        margin-bottom: 20px;
    }

    #care_card_section .care_card.card_3 img {
        right: -40px;
    }

    #care_card_section .care_card.card_7 img {
        top: -75%;
        left: -40%;
        width: 270px;
    }

    #care_card_section .care_card.card_9 img {
        bottom: -40%;
        right: -13%;
        max-width: 200px;
    }

    #care_count_section {
        padding: 51px 0;
    }

    #care_count_section .care_count_para {
        font-size: 25px;
        display: flex;
        text-align: center;
        max-width: fit-content;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    #resource_hero_section .res_hero_main {
        /* padding: 51px 0 80px 0; */
        padding: 51px 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        height: auto;
    }

    #resource_hero_section .res_para_1 {
        font-size: 20px;
        line-height: 118%;
    }

    #resource_hero_section .res_para_2 {
        font-size: 52px;
        line-height: 54px;
        max-width: fit-content;
        margin-bottom: 20px;
    }

    #resource_hero_section .res_hero_img {
        position: static;
        margin-left: auto;
        margin-right: auto;
        height: 180px;
        max-width: 121px;
    }

    #resource_main_section .resource_link {
        font-size: 20px;
        padding-bottom: 0;
    }

    #resource_main_section .res_link_wrapper {
        /* padding: 30px 0; */
        padding: 20px 0;
    }

    #resource_main_section .res_main_para {
        padding: 15px 0 22px 54px;
    }

    /* CareInsight page */

    #careInsight_hero_section .careInsight_hero_main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 51px 0;
        height: auto;
    }

    #careInsight_hero_section .careInsight_para_1 {
        font-size: 20px;
        line-height: 118%;
    }

    #careInsight_hero_section .careInsight_para_2 {
        margin-bottom: 20px;
        font-size: 52px;
        line-height: 54px;
    }

    #careInsight_hero_section .careInsights_img {
        position: static;
    }

    #careInsight_hero_description .description_para {
        /* padding: 83px 0; */
        padding: 23px 0;
    }

    #careInsight_hero_description .description_para img {
        width: 45px;
    }

    #careInsight_hero_description .description_para p {
        /* font-size: 20px;
        line-height: 30px;
        padding-left: 20px; */
        font-size: 16px;
        line-height: 27px;
        padding-left: 15px;
        ;
    }

    #careInsight_main_section .careInsightMain_left h2 {
        font-size: 22px;
        line-height: 32px;
    }

    #careInsight_main_section .careInsightMain_left p {
        font-size: 18px;
        line-height: 25px;
        padding-bottom: 20px;
    }

    #careInsight_main_section .careInsightMain_right {
        max-height: 230px;
        height: 230px;
    }

    #careInsightCard_section {
        padding-top: 35px;
        padding-bottom: 45px;
    }

    #careInsightCard_section .articleNextBtn,
    #careInsightCard_section .articlePrevBtn {
        margin-top: 20px;
    }

    /* Article Page */

    #article_hero_section .wrapper .overlay_bg {
        background: linear-gradient(90deg,
                #391a52 0%,
                rgba(57, 26, 82, 0) 134.15%);
    }

    #article_hero_section .article_hero_main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 71px 0;
        height: 100%;
    }

    #article_hero_section .article_para_1 {
        font-size: 20px;
        line-height: 118%;
    }

    #article_hero_section .article_para_2 {
        font-size: 46px;
        line-height: 54px;
        max-width: 340px;
        height: fit-content;
        /* margin-bottom: 20px; */
    }

    #article_hero_description .description_para {
        font-size: 16px;
        line-height: 27px;
        padding: 37.5px 5px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    #article_main_section {
        padding-top: 32px;
        padding-bottom: 22px;
    }

    #article_main_section .articleMain_header {
        margin-bottom: 32px;
    }

    #article_main_section .articleMain_left .tcare_team img {
        max-width: 35px;
    }

    #article_main_section .articleMain_left .tcare_team p {
        font-size: 18px;
        padding-left: 13px;
    }

    #article_main_section .articleMain_right h2 {
        font-size: 15px;
        line-height: 30px;
        margin-bottom: 0;
    }

    #article_main_section .articleMain_right .para_1 {
        font-size: 15px;
        line-height: 25px;
    }

    #article_main_section .articleMain_content p {
        font-size: 16px;
        line-height: 30px;
        padding-bottom: 23px;
    }

    /* More insights */

    .moreInsights_header {
        font-size: 30px;
        line-height: 38px;
        padding-bottom: 27px;
    }

    /* Contact section */
    #contact_section {
        padding: 35px 0;
    }

    #contact_section .contact_hero_main {
        flex-direction: column;
        padding: 30px 10px 60px 10px;
    }

    #contact_section img.chat {
        margin-bottom: 10px;
    }

    #contact_section .contact_form .submit_btn {
        min-width: 100%;
    }

    #go_mobile_section {
        padding: 20px 0;
    }

    #go_mobile_section .go_mobile_outer {
        float: none !important;
        margin-left: auto;
        margin-right: auto;
        /* padding-right: 20px; */
        justify-content: center;
        flex-wrap: wrap;
    }

    #go_mobile_section .go_mobile_left {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
    }

    #go_mobile_section .go_mobile_para {
        font-size: 16px;
        line-height: 21.86px;
        /* max-width: 155px; */
        max-width: 163px;
        padding-bottom: 0;
    }

    #go_mobile_section .qrCode_div {
        margin-left: 22px !important;
    }

    #go_mobile_section .qrCodeSmall {
        width: 100px;
    }

    #go_mobile_section .go_mobile_left .download_app_lg {
        display: none;
    }

    #go_mobile_section .qrCode_div .fullscreen_btn_lg {
        display: none;
    }

    #go_mobile_section .go_mobile_left .fullscreen_btn_mob {
        display: block !important;
    }

    #go_mobile_section .go_mobile_left .fullscreen_btn_mob img {
        width: 31px;
    }

    #go_mobile_section .download_app_mob {
        display: block !important;
    }

    #fullscreenModal .modal-dialog {
        margin-left: auto;
        margin-right: auto;
    }

    #fullscreenModal .fullscreen_outer {
        flex-direction: column;
        align-items: center;
    }

    #fullscreenModal .fullscreen_left {
        order: 2;
    }

    #fullscreenModal .fullscreen_left .go_mobile_para {
        font-size: 18px;
        max-width: 250px;
        line-height: 26px;
        padding-bottom: 0;
    }

    #fullscreenModal .fullscreen_outer img.qrCodeLarge {
        max-width: 250px;
    }

    footer {
        height: auto;
        padding: 40px 0;
        padding-bottom: 37px;
    }

    footer .copyRight_para {
        display: flex;
        flex-direction: column;
    }

    footer .copyRight_row {
        padding-top: 32px;
    }

    footer .copyRight_para a {
        margin-left: 0;
        width: fit-content;
    }

    /* ******* For GTL ******* */
    /*#gtl_hero_section {
         background-color: var(--darkPurple) !important;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100%;
        padding: 50px 0 132px 0; 
    }*/
    #gtl_hero_section {
        background: url('../Images/gt_banner_vertical.png') no-repeat center;
        background-size: cover;
    }

    #gtl_hero_section .col {
        padding: 0;
    }

    #gtl_hero_section .wrapper {
        background: linear-gradient(0deg, #391A52 0%, rgba(57, 26, 82, 0.8) 35%, rgba(57, 26, 82, 0.38) 81.53%);
        /* padding: 50px 0 132px 0;  */
        padding-top: 50px;
    }

    #gtl_hero_section .hero_div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        /* background-color: var(--darkPurple); */
        /* background: url('../Images/hero_bg_vertical.svg'); */
        padding: 0;
        height: auto;
    }

    #gtl_hero_section .hero_div .hero_left {
        width: 100%;
        align-items: center;
        padding-bottom: 50px;
    }

    #gtl_hero_section .hero_div .hero_right {
        background: linear-gradient(to right, transparent 0%, transparent 50%, #25377d 50%, #25377d 100%);
        width: 100%;
    }

    #gtl_hero_section .hero_div .hero_left .hero_para_1 {
        max-width: 241px;
        font-size: 20px;
        line-height: 118%;
    }

    #gtl_hero_section .hero_div .hero_left .hero_para_2 {
        max-width: 410px;
        font-size: 46px;
        line-height: 54px;
        height: 100%;
    }

    #gtl_hero_section .hero_div img {
        width: 55%;
        position: static;
    }

    #hero_description .description_para.gtl {
        height: auto;
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
    }

    .my_card_1.gtl {
        max-width: 220px;
    }


    #caregiver_hero_section.gtl {
        background: url('../Images/gt_banner_vertical.png') no-repeat center;
        background-size: cover;
    }

    #caregiver_hero_section.gtl .wrapper {
        background: linear-gradient(0deg, #391A52 0%, rgba(57, 26, 82, 0.8) 35%, rgba(57, 26, 82, 0.38) 81.53%);
    }

    #gtl_care_card_section .left p {
        font-size: 32px;
        line-height: 48px;
    }

    #gtl_care_card_section .left img {
        top: 30%;
    }

    /* #gtl_care_card_section .right .my_card {
        width: 190px;
        height: 190px;
        background-size: contain;
    }

    #gtl_care_card_section .right .my_card p {
        font-size: 22px;
        line-height: 33px;
        max-width: 160px;
    }

    #gtl_care_card_section .right .my_card.card_1 {
        order: 2;
        left: -14%;
        top: -8.7%;
    } */

    #resource_hero_section.gtl {
        background: url('../Images/gt_banner_vertical.png') no-repeat center;
        background-size: cover;
    }

    #resource_hero_section.gtl .wrapper {
        background: linear-gradient(0deg, #391A52 0%, rgba(57, 26, 82, 0.8) 35%, rgba(57, 26, 82, 0.38) 81.53%);
    }

    #rec_cash_hero_section {
        background: url('../Images/gt_banner_vertical.png') no-repeat center;
        background-size: cover;
    }

    #rec_cash_hero_section .wrapper {
        background: linear-gradient(0deg, #391A52 0%, rgba(57, 26, 82, 0.8) 35%, rgba(57, 26, 82, 0.38) 81.53%);
    }

    #gtl_rec_cash_main_section .cash_link {
        font-size: 20px;
        line-height: 30px;
    }

    #gtl_rec_cash_main_section iframe {
        width: 100%;
    }

    #careInsight_hero_section.gtl {
        background: url('../Images/gt_banner_vertical.png') no-repeat center;
        background-size: cover;
    }

    #careInsight_hero_section.gtl .wrapper {
        background: linear-gradient(0deg, #391A52 0%, rgba(57, 26, 82, 0.8) 35%, rgba(57, 26, 82, 0.38) 81.53%);
    }

    footer .copyRight_para.gtl {
        flex-direction: row;
        font-size: 14px;
    }

    /* ******* For Reframe Financial ******** */

    #reframe_hero_section .hero_div .hero_left .hero_para_1 {
        line-height: 118%;
    }

    #reframe_hero_section .hero_div .hero_left .hero_para_2 {
        font-size: 52px;
        line-height: 54px;
        height: 100%;
    }

    #hero_description.reframe .description_para {
        font-size: 16px;
        line-height: 33px;
        text-align: center;
    }

    #care_card_section.reframefinancial .care_card.card_9 img {
        right: -12%;
        top: 66%;
    }

    #resource_hero_section.reframefinancial .res_para_2 {
        font-size: 52px;
        line-height: 54px;
    }

    #resource_hero_section.reframefinancial .res_hero_img {
        height: auto;
    }

    #careInsight_hero_section.reframefinancial .careInsight_para_2 {
        font-size: 52px;
        line-height: 54px;
    }

    /* Article Page */
    #article_hero_section.reframefinancial .article_para_2 {
        font-size: 46px;
        line-height: 54px;
    }

}

@media screen and (max-width:480px) {
    #hero_section .hero_div .hero_left {
        padding-bottom: 0;
    }

    #gtl_care_card_section {
        max-height: 1330px;
    }

    #gtl_care_card_section .right {
        left: -12%;
    }

    #gtl_care_card_section .right .my_card {
        max-width: 190px;
        max-height: 190px;
        background-size: contain;
    }

    #gtl_care_card_section .right .my_card p {
        font-size: 21px;
        line-height: 33px;
        max-width: 150px;
    }

    #gtl_care_card_section .right .my_card.card_1 {
        left: -11%;
    }

    #gtl_care_card_section .right .my_card.card_2 {
        right: -120px;
    }

    #gtl_care_card_section .right .my_card.card_3 {
        right: -120px;
    }

    #gtl_care_card_section .right .my_card.card_4 {
        right: -120px;
    }

    #gtl_care_card_section .right .my_card.card_5 {
        left: -11.4%;
    }

    #gtl_care_card_section .right .my_card.card_6 {
        left: -11.9%;
    }

    /* ******* For Reframe Financial ******** */
    #care_card_section.reframefinancial .care_card.card_7 img {
        top: -70%;
        left: -17%;
        max-width: 180px;
    }

    #care_card_section .care_card.card_9 img {
        right: -18%;
        top: 62%;
        max-width: 180px;
    }
}

@media screen and (max-width:388px) {
    footer .copyRight_para.gtl {
        flex-direction: column;
        font-size: 14px;
    }

    /* *******   For Reframe Financial  ********* */
    /* Article Page */
    #article_hero_section.reframefinancial .article_para_2 {
        font-size: 42px;
    }
}