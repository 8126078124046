.App2 {
    max-width: 600px;
    margin: 0 auto;
    padding-left: 1em;
    padding-right: 1em;
}


.create input[type="date"]:before {
    content: attr(placeholder) !important;
    color: #aaa;
    margin-right: 0.5em;
    /*width: 100%; !* setting width to 100% gets rid of mm/dd/yyyy *!*/
}

.create input[type="date"]:focus:before,
.create input[type="date"]:valid:before {
    content: "";
}

.update input[type="date"]::before {
    color: #999999;
    content: attr(placeholder) !important;
}

.update input[type="date"] {
    color: #ffffff;
}

.update input[type="date"]:focus,
.update input[type="date"]:valid {
    font-size: 14px;
    font-weight: 500;
    color: #391A52;
}
.update input[type="date"]:focus::before,
.update input[type="date"]:valid::before {
    content: "" !important;
}

.App2 .Button1 {
    color: lightpink;
}

.App2 .formContainer1 {
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    /*background-color: #F8F0EC;*/
}

.App2 .formInput1 {
    /*margin-bottom: 1em;*/
    border-radius: 25px;
}

.App2 .formInput2 {
    /*background: #817099 no-repeat bottom;*/
    /*margin-bottom: 1em;*/
}

.App2 .textAndIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.App2 .profileHeader {
    display: flex;
    flex-direction: row;
}

.App2 form {
    max-width: 500px;
    margin: 0 auto;
}

.App2 h1 {
    font-weight: 100;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(79, 98, 148);
}

.App2 .form {
    /*background: #512E5F;*/
    max-width: 400px;
    margin: 0 auto;
}



.App2 input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #391A52;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
}

.App2 label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 20px;
    color: white;
    font-size: 14px;
    font-weight: 200;
}

.App2 input[type="submit"] {
    background: #ec5990;
    color: white;
    text-transform: uppercase;
    border: none;
    margin-top: 40px;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 10px;
}

.App2 input[type="submit"]:hover {
    background: #bf1650;
}

.App2 input[type="button"]:active,
.App2 input[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
}


.App2 input:disabled {
    opacity: 0.4;
}

.App2 input[type="button"]:hover {
    transition: 0.3s all;
}

.App2 input[type="button"],
.App2 input[type="submit"] {
    -webkit-appearance: none;
}



.App2 .profile button {
    display: inline-block;
    padding: 15px 25px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
}

.App2 .profile button:hover {background-color: #3e8e41}

.App2 .profile button:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}


/* select stuff */


.App2 select {
    /* Reset Select */

    appearance: none;
    outline: 0;
    border-radius: 4px;
    box-shadow: none;
    /* Personalize */
    flex: 1;
    padding: 0 1em;
    color: black;
    /*background-color: #FEF9F1;*/
    background-color: #FFF;
    background-image: none;
    cursor: pointer;

}

.App2 select:disabled {
    background: #8E7FA3 no-repeat bottom;
    color: #6F657D;
}

/* Remove IE arrow */
.App2 select::-ms-expand {
    display: none;
}
/* Custom Select wrapper */
.App2 .select {
    position: relative;
    display: flex;
    width: 20em;
    height: 2.85em;
    border-radius: .05em;
    overflow: hidden;
}
/* Arrow */
.App2 .select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    transition: .25s all ease;
    pointer-events: none;
}

.App2 .select:disabled::after {
    background: #817099 no-repeat bottom;
}

/* Transition */
.App2 .select:hover::after {
    color: #f39c12;
}

/* Other styles*/
/*body {*/
/*    color: #fff;*/
/*    background: var(--background-gradient);*/
/*}*/
.App2 h1 {
    margin: 0 0 0.25em;
}
