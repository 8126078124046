@media (min-width:3000px) {
  #hero_section {
    background: linear-gradient(to right,
        var(--darkPurple) 0%,
        var(--darkPurple) 60%,
        var(--mint) 56%,
        var(--mint) 100%);
  }
}

/* @media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
} */

@media screen and (max-width:1770px) {

  /********** For Reframe Financial *********/

  #care_card_combined_section.reframefinancial,
  #resource_main_section.reframefinancial,
  #combined_careInsight_hero_description.reframefinancial,
  #combined_article_main_section.reframefinancial,
  #contact_inner_section.reframefinancial {
    background-size: 250px;
  }
}

@media screen and (max-width:1685px) {

  /********** For Reframe Financial *********/

  #care_card_combined_section.reframefinancial,
  #resource_main_section.reframefinancial,
  #combined_careInsight_hero_description.reframefinancial,
  #combined_article_main_section.reframefinancial,
  #contact_inner_section.reframefinancial {
    background: none;
  }
}

@media screen and (max-width: 1600px) {
  #hero_section {
    background: linear-gradient(to right,
        var(--darkPurple) 0%,
        var(--darkPurple) 74%,
        var(--mint) 63%,
        var(--mint) 100%);
  }


  #hero_section .hero_div img {
    /* width: 576px; */
    /* right: -234px; */
    right: -196px;
    width: 120%;
    height: 100%;
  }

  /*************** Caregiver article ***************/

  #article_hero_section .wrapper .overlay_bg {
    background: linear-gradient(90deg,
        #391a52 53.75%,
        rgba(57, 26, 82, 0) 94.15%);
  }


  /****** For GTL ******/
  #gtl_hero_section .hero_div img {
    right: -196px;
    width: 175%;
    height: 100%;
  }

}


@media screen and (max-width: 1400px) {

  .container {
    max-width: 1200px;
  }

  /* .my_navbar {
    padding: 30px 0;
  } */

  .navbar .navbar-brand img {
    width: 150px;
  }

  #hero_section {
    background: linear-gradient(to right,
        var(--darkPurple) 0%,
        var(--darkPurple) 77%,
        var(--mint) 72%,
        var(--mint) 100%);
  }

  #hero_section .hero_div {
    /* padding: 79px 0; */
    /* padding: 137px 0; */
    height: 400px;
  }

  /* .my_navbar{
    padding: 25px  0 30px 0;
  } */
  #hero_section .hero_div .hero_left .hero_para_1 {
    font-size: 28px;
  }

  #hero_section .hero_div .hero_left .hero_para_2 {
    font-size: 66px;
    line-height: 80px;
    max-width: 565px;
  }

  /* #hero_section .wrapper {
    background-position: right;
  } */
  #hero_section .hero_div img {
    /* width: 423px; */
    /* right: -100px; */
    right: -25%;
    width: 90%;
  }

  #hero_description .description_para {
    /* padding: 43px 0; */
    font-size: 26px;
    /* max-width: 886px; */
    height: 200px;
  }

  #hero_description .description_para.coBrand {
    padding: 40px 0;
    max-width: 988px;
  }

  /* ********** Started Section ************ */
  #started_section {
    /* padding-bottom: 130px; */
    padding-bottom: 93px;
  }

  #started_section .started_heading {
    font-size: 40px;
  }

  .my_card_1 p {
    font-size: 26px;
    max-width: 174px;
  }

  /* ********** Caregiver Section ************** */
  #caregiver_hero_section .care_hero_main {
    /* padding: 137px 0; */
    padding: 0;
    height: 400px;
  }

  #caregiver_hero_section .care_para_1 {
    font-size: 28px;
    line-height: 43px;
  }

  #caregiver_hero_section .care_para_2 {
    font-size: 66px;
    line-height: 80px;
    max-width: 491px;
  }

  #caregiver_hero_section .care_hero_img {
    max-width: 250px;
  }

  #care_description .description_para {
    /* padding: 99px 0; */
    padding: 62px 0;
    max-width: 612px;
  }

  /* ********** Resource Section ************ */
  #resource_hero_section .res_hero_main {
    /* padding: 79px 0; */
    /* padding: 137px 0; */
    padding: 0;
    height: 400px;
  }

  #resource_hero_section .wrapper {
    background-position: 175px;
  }

  #resource_hero_section .res_hero_img {
    right: 2px;
    /* top: 74px; */
    /* max-width: 172px; */
    /* max-width: 215px; */
    max-width: 180px;
  }

  #resource_hero_section .res_para_1 {
    font-size: 28px;
  }

  #resource_hero_section .res_para_2 {
    font-size: 66px;
    line-height: 80px;
    max-width: 565px;
  }

  /* #resource_main_section .res_link_wrapper {
    padding: 50px 0;
  } */

  /*************** Caregiver Insights ***************/
  #careInsight_hero_section .careInsight_hero_main {
    height: 400px;
  }

  #careInsight_hero_section .careInsight_para_1 {
    font-size: 28px;
    line-height: 43px;
  }

  #careInsight_hero_section .careInsight_para_2 {
    font-size: 66px;
    line-height: 80px;
    max-width: 491px;
  }

  #careInsight_hero_section .careInsights_img {
    max-width: 200px;
  }

  #careInsight_hero_description .description_para {
    /* padding: 83px 0; */
    padding: 43px 0;
  }

  #careInsightCard_section .careInsightCard_body p {
    font-size: 23px;
  }

  #careInsight_main_section .careInsightMain_left {
    padding: 72px 0 61px 72px;
  }

  /*************** Caregiver article ***************/
  #article_hero_section {
    height: 100%;
  }

  #article_hero_section .wrapper {
    /* height: 370px; */
    height: 100%;
  }

  #article_hero_section .article_hero_main {
    /* height: 370px; */
    height: 400px;
  }

  #article_hero_section .article_para_1 {
    /* font-size: 26px; */
    font-size: 28px;
  }

  #article_hero_section .article_para_2 {
    font-size: 66px;
    line-height: 80px;
    max-width: 665px;
  }


  /* #article_hero_section .wrapper .overlay_bg {
    background: linear-gradient(90deg,
        #391a52 43.75%,
        rgba(57, 26, 82, 0) 73.15%);
  } */

  #article_hero_section .wrapper .overlay_bg {
    background: linear-gradient(90deg,
        #391a52 56.75%,
        rgba(57, 26, 82, 0) 94.15%);
  }

  #article_hero_description .description_para {
    padding: 43px 0;
    font-size: 26px;
    line-height: 36px;
    max-width: 822px;
  }

  #article_main_section {
    padding-top: 52px;
    padding-bottom: 52px;
  }


  /*************** Go Mobile ***************/

  #go_mobile_section {
    background-color: var(--primaryCream);
    /* padding: 50px 0; */
    padding: 0;
    min-height: 200px;
  }

  #fullscreenModal .modal-content .modal-body {
    padding: 46px 54px 58px 18px;
  }

  #fullscreenModal .fullscreen_outer img.qrCodeLarge {
    max-width: 300px;
  }

  #fullscreenModal .go_mobile_para {
    font-size: 26px;
    max-width: 300px;
  }




  /* ************* Footer ************ */

  footer {
    /* padding-top: 50px;
    padding-bottom: 40px; */
    padding: 0;
    height: 200px;
  }

  footer .footerLogo {
    max-width: 150px;
    max-height: 38px;
  }

  /****** For GTL ******/

  #hero_description .description_para.gtl p br {
    display: none;
  }

  #gtl_hero_section .hero_div {
    height: 400px;
  }

  #gtl_hero_section .hero_div img {
    right: -35%;
    width: 175%;
    height: 100%;
  }

  #gtl_hero_section .hero_div .hero_left .hero_para_1 {
    font-size: 28px;
  }

  #gtl_hero_section .hero_div .hero_left .hero_para_2 {
    font-size: 66px;
    line-height: 80px;
    max-width: 565px;
  }

  #hero_description .description_para.gtl {
    font-size: 26px;
    height: 200px;
  }

  #gtl_care_card_section .left p {
    /* margin-bottom: 90px; */
    max-width: 360px;
    font-size: 36px;
  }

  #gtl_care_card_section .left img {
    max-width: 450px;
    left: -10%;
  }

  #gtl_care_card_section .right {
    left: -7%;
  }

  #gtl_care_card_section .right .my_card.card_1 {
    top: 13%;
    right: -5%;
  }

  #gtl_care_card_section .right .my_card.card_2 {
    right: -2%;
    top: -3%;
  }

  #gtl_care_card_section .right .my_card.card_3 {
    right: -70%;
    top: -20%;
  }

  #gtl_care_card_section .right .my_card.card_4 {
    top: -3%;
  }

  #gtl_care_card_section .right .my_card.card_5 {
    top: -20%;
  }

  #gtl_care_card_section .right .my_card.card_6 {
    right: -34%;
    top: -20%;
  }

  #rec_cash_hero_section .hero_main {
    /* padding: 137px 0; */
    padding: 0;
    height: 400px;
  }

  #rec_cash_hero_section .para_1 {
    font-size: 28px;
    line-height: 43px;
  }

  #rec_cash_hero_section .para_2 {
    font-size: 66px;
    line-height: 80px;
    max-width: 600px;
  }

  #rec_cash_hero_section .hero_img {
    max-width: 300px;
  }


  /********** For Reframe Financial *********/

  .navbar.reframe .navbar-brand img {
    width: 100%;
  }

  #reframe_hero_section,
  #caregiver_hero_section.reframefinancial,
  #resource_hero_section.reframefinancial,
  #careInsight_hero_section.reframefinancial,
  #contact_header_section.reframefinancial {
    background-position: 130% 0%;
    background-size: contain;
  }

  #contact_header_section.reframefinancial {
    /* background-position: 136.5% 0%; */
    height: 400px;
  }

  #reframe_hero_section .hero_div {
    height: 400px;
  }

  #reframe_hero_section .hero_div .hero_left .hero_para_1 {
    font-size: 28px;
    /* line-height: 43px; */
  }

  #reframe_hero_section .hero_div .hero_left .hero_para_2 {
    line-height: 80px;
  }

  #hero_description.reframe .description_para.coBrand {
    max-width: 100%;
  }

  /* Article Page */
  #article_hero_section.reframefinancial .wrapper .overlay_bg {
    background: linear-gradient(90deg,
        var(--reframeDarkBlue) 56.75%,
        rgba(57, 26, 82, 0) 94.15%);
  }
}


@media screen and (max-width: 1300px) {
  #hero_section .hero_div img {
    right: -15%;
    width: 90%;
  }

  /* For GTL */
  #gtl_hero_section .hero_div img {
    right: -23%;
    width: 175%;
    height: 100%;
  }

  #gtl_care_card_section .left img {
    max-width: 400px;
    left: 0%;
    top: 4%;
  }

  #gtl_care_card_section .right {
    left: 0;
  }

  #gtl_care_card_section .right .my_card.card_1 {
    left: 0%;
  }

  #gtl_care_card_section .right .my_card.card_2 {
    left: -3%;
    top: -3%;
  }

  #gtl_care_card_section .right .my_card.card_3 {
    right: -61%;
  }

  #gtl_care_card_section .right .my_card.card_4 {
    left: -3.6%;
  }

  #gtl_care_card_section .right .my_card.card_5 {
    left: 0;
  }


}

@media screen and (max-width: 1290px) {
  /* .container {
    padding: 0 30px;
  } */

  /******** For Reframe Financial *******/
  #care_card_section.reframefinancial .care_card.card_7 img {
    left: -45%;
    top: -90%;
    max-width: 237px;
  }
}

@media screen and (min-width:1212px) and (max-width: 1300px) {

  /* For GTL */

  #gtl_care_card_section .outer {
    height: 850px;
  }

  #gtl_care_card_section .right .my_card.card_1 {
    left: -3%;
    top: 14%;
  }

  #gtl_care_card_section .right .my_card.card_2 {
    left: -7%;
    /* top: 13%; */
  }

  #gtl_care_card_section .right .my_card.card_3 {
    right: 11%;
    top: 15%;
  }

  #gtl_care_card_section .right .my_card.card_4 {
    left: 26%;
    top: -18%;
  }

  #gtl_care_card_section .right .my_card.card_5 {
    left: -36%;
    top: 0;
  }

  #gtl_care_card_section .right .my_card.card_6 {
    right: 11%;
    top: 0%;
  }
}

@media screen and (min-width:1200px) and (max-width: 1211px) {

  /* For GTL */

  #gtl_care_card_section .outer {
    height: 850px;
  }

  #gtl_care_card_section .right {
    left: -20px;
  }

  #gtl_care_card_section .right .my_card.card_1 {
    top: 14%;
  }

  #gtl_care_card_section .right .my_card.card_2 {
    left: -4%;
  }

  #gtl_care_card_section .right .my_card.card_3 {
    right: -59%;
    top: -21%;
  }

  #gtl_care_card_section .right .my_card.card_4 {
    left: -4%;
    top: -4%;
  }

  #gtl_care_card_section .right .my_card.card_5 {
    top: -22%;
  }

  #gtl_care_card_section .right .my_card.card_6 {
    right: -25%;
    top: -22%;
  }
}

@media screen and (max-width: 1200px) {

  /* .container{
    max-width: 1140px;
  } */

  /* .container {
    padding: 0 30px;
  } */

  /* #hero_section .hero_div {
    padding: 68px 0;
  } */
  .navbar .navbar-brand img {
    width: 133px;
  }

  /* .my_navbar .navbar-brand .coBrandLogo {
    max-width: 125px;
  } */

  #hero_section .hero_div img {
    /* width: 372px; */
    right: -40px;
  }

  /* #hero_section .hero_div .hero_para_1 {
    font-size: 28px;
  }

  #hero_section .hero_div .hero_para_2 {
    font-size: 76px;
    line-height: 86px;
    max-width: 700px;
  } */

  #hero_description .description_para {
    max-width: 835px;
    font-size: 26px;
    line-height: 43px;
  }

  #started_section .started_heading {
    font-size: 40px;
  }

  /* #caregiver_hero_section .care_para_1 {
    font-size: 28px;
  }

  #caregiver_hero_section .care_para_2 {
    max-width: 590px;
    font-size: 76px;
  } */

  .my_card_1 {
    max-width: 298px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .my_card_1 p {
    font-size: 26px;
    max-width: 176px;
  }

  #care_card_section .care_card.card_9 img {
    max-width: 250px;
  }

  /* #resource_hero_section .res_para_1 {
    font-size: 26px;
  }

  #resource_hero_section .res_para_2 {
    font-size: 66px;
  } */

  /*************** Caregiver Insights ***************/
  /* #careInsight_hero_section .careInsight_para_1 {
    font-size: 28px;
  }

  #careInsight_hero_section .careInsight_para_2 {
    font-size: 76px;
    line-height: 86px;
    max-width: 571px;
  } */

  #careInsight_hero_description .description_para img {
    width: 60px;
  }

  #careInsight_hero_description .description_para p {
    font-size: 26px;
    max-width: 642px;
    padding-left: 40px;
  }

  #careInsight_main_section .careInsightMain_left {
    padding: 72px 0 61px 52px;
  }

  #careInsight_main_section .careInsightMain_right {
    position: absolute;
    right: 0;
  }

  #article_hero_section .wrapper .overlay_bg {
    background: linear-gradient(90deg,
        #391a52 49.75%,
        rgba(57, 26, 82, 0) 94.15%);
  }

  footer .footerLogo {
    max-width: 133px;
    max-height: 38px;
  }


  /* For GTL */

  #gtl_care_card_section .left p {
    margin-bottom: 15px;
    max-width: 360px;
    font-size: 35px;
  }

  #gtl_care_card_section .left img {
    max-width: 350px;
  }

  #gtl_care_card_section .right {
    left: 0;
    width: 630px;
  }



  #gtl_care_card_section .right .my_card {
    width: 215px;
    height: 215px;
    background-size: contain;
  }

  #gtl_care_card_section .right .my_card.card_6 {
    right: -27%;
    top: -20%;
  }

  /******* Reframe Financial *******/

  #article_hero_section.reframefinancial .wrapper .overlay_bg {
    background: linear-gradient(90deg,
        var(--reframeDarkBlue) 49.75%,
        rgba(57, 26, 82, 0) 94.15%);
  }
}

@media screen and (max-width:1120px) {

  /* For GTL */
  #gtl_care_card_section .outer {
    flex-wrap: wrap;
    justify-content: center;
  }

  #gtl_care_card_section .left {
    margin-bottom: 80px;
  }

  #gtl_care_card_section .left p {
    text-align: center;
  }

  #gtl_care_card_section .right .my_card.card_3 img {
    max-width: 210px;
  }

  #gtl_care_card_section .right .my_card.card_5 img {
    max-width: 320px;
  }
}

@media screen and (min-width:992px) and (max-width:1156px) {
  /* .navbar .navbar-brand img {
    width: 150px;
  } */

  /* .my_navbar ul li {
    margin-right: 8px;
  } */

  /* .my_navbar ul li a {
    font-size: 15px;
    padding: 4px 17px !important;
  } */

  .my_navbar ul li.dropdown a.dropdown-toggle {
    min-width: auto;
  }

  #hero_section .hero_div .hero_left .hero_para_1 {
    font-size: 26px;
  }


  #caregiver_hero_section .care_hero_main {
    /* padding: 99px 0; */
    padding: 0;
  }

  #caregiver_hero_section .care_para_1 {
    font-size: 26px;
  }

  #caregiver_hero_section .care_para_2 {
    max-width: 515px;
    font-size: 66px;
    line-height: 80px;
  }

  #care_description .description_para {
    padding: 59px 0;
    max-width: 534px;
  }

  #care_description .description_para p {
    font-size: 26px;
    line-height: 36px;
  }

  #care_card_section .care_card_row {
    margin-bottom: 22px;
  }

  #care_card_section .care_card p {
    font-size: 20px;
  }

  #care_card_section .care_card.card_1 p {
    max-width: 164px;
  }

  #care_card_section .care_card.card_2 p,
  #care_card_section .care_card.card_5 p {
    max-width: 135px;
  }

  #care_card_section .care_card.card_3 p {
    max-width: 175px;
  }

  #care_card_section .care_card.card_4 p,
  #care_card_section .care_card.card_7 p,
  #care_card_section .care_card.card_8 p {
    max-width: 186px;
  }

  #care_card_section .care_card.card_7 img {
    max-width: 365px;
  }

  #care_card_section .care_card.card_6 p,
  #care_card_section .care_card.card_9 p {
    max-width: 170px;
  }




  #care_count_section .care_count_anchor {
    font-size: 15px;
    line-height: 15px;
  }

  #care_count_section .care_ul li {
    font-size: 15px;
  }


  /* Resources page */

  #resource_hero_section .res_para_1 {
    font-size: 26px;
  }


  /* CareInsights Page */
  #careInsight_hero_section .careInsight_para_1 {
    font-size: 26px;
  }

  #careInsight_hero_section .careInsight_para_2 {
    font-size: 66px;
    line-height: 80px;
  }

  /* Article section */

  #article_hero_section .article_para_1 {
    font-size: 26px;
  }

  /* footer .footerLogo {
    width: 150px;
  } */

  /* For GTL  */
  #gtl_hero_section .hero_div .hero_left .hero_para_1 {
    font-size: 26px;
  }

  #rec_cash_hero_section .hero_main {
    /* padding: 99px 0; */
    padding: 0;
  }

  #rec_cash_hero_section .para_1 {
    font-size: 26px;
  }

  #rec_cash_hero_section .para_2 {
    max-width: 600px;
    font-size: 66px;
    line-height: 80px;
  }

  /* ******** For Reframe financial ******* */
  #reframe_hero_section .hero_div .hero_left .hero_para_1 {
    font-size: 26px;
  }
}

/* @media screen and (max-width: 991px) {

} */

@media screen and (max-width: 991px) {
  /* #hero_section .wrapper {
    background-image: url("../Images/hero_small_bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  } */

  .my_navbar {
    padding: 20px 0;
    /* height: 100px; */
  }

  .my_navbar ul li {
    width: 100%;
  }

  .my_navbar ul.navbar-nav {
    align-items: start !important;
    margin-top: 20px;
  }

  .my_navbar ul.dropdown-menu {
    width: 100%;
  }

  .download_app {
    margin-top: 20px;
    align-self: center;
  }

  .loginBtn {
    margin-top: 20px;
    align-self: center;
    margin-left: 0;
  }

  .my_navbar ul li.beforeSignUp {
    margin-bottom: 10px;
  }

  .my_navbar ul .signUpBtn {
    margin-left: 25px;
  }

  #hero_section {
    background: linear-gradient(to right,
        var(--darkPurple) 0%,
        var(--darkPurple) 80%,
        var(--mint) 80%,
        var(--mint) 100%);
  }

  #hero_section .hero_div {
    /* padding: 79px 0; */
    padding: 0;
  }

  #hero_section .hero_div .hero_left .hero_para_1 {
    font-size: 22px;
  }

  #hero_section .hero_div .hero_left .hero_para_2 {
    font-size: 56px;
    line-height: 71px;
    max-width: 450px;
  }

  #hero_section .hero_div img {
    /* width: 225px; */
    right: -36px;
    width: 100%;
  }

  #hero_description .description_para {
    font-size: 21px;
    line-height: 33px;
    /* padding: 37px 0; */
    padding: 0;
    /* text-align: center; */
  }

  #started_section .started_heading {
    font-size: 36px;
  }

  .my_card_1 {
    max-width: 256px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .my_card_1 p {
    font-size: 24px;
    max-width: 160px;
    line-height: 36px;
  }

  .my_card_1 span {
    width: 61px;
    height: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #caregiver_hero_section .care_hero_main {
    /* padding: 79px 0; */
    padding: 0;
  }

  #caregiver_hero_section .care_para_1 {
    font-size: 22px;
  }

  #caregiver_hero_section .care_para_2 {
    max-width: 450px;
    font-size: 56px;
    line-height: 71px;
  }

  #caregiver_hero_section .care_hero_img {
    max-width: 190px;
  }

  #care_description .description_para {
    /* padding: 64px 0; */
    padding: 42px 0;
    max-width: 400px;
  }

  #care_description .description_para p {
    font-size: 21px;
    line-height: 33px
  }

  #care_card_section .care_card_outer {
    width: 80%;
  }

  #care_card_section .care_card_row {
    margin-bottom: 22px;
  }

  #care_card_section .care_card {
    max-width: 276px;
    height: 157px;
    border-radius: 21px;
  }

  #care_card_section .care_card p {
    font-size: 18px;
    line-height: 30px;
  }

  #care_card_section .care_card.card_1 p {
    max-width: 123px;
  }

  #care_card_section .care_card.card_2 p,
  #care_card_section .care_card.card_5 p {
    max-width: 130px;
  }

  #care_card_section .care_card.card_3 p {
    max-width: 130px;
  }

  #care_card_section .care_card.card_3 img {
    right: -80px;
    top: -32%;
    width: 120px;
  }


  #care_card_section .care_card.card_7 img {
    top: -95%;
    left: -168px;
    width: 300px;
  }

  #care_card_section .care_card.card_4 p,
  #care_card_section .care_card.card_7 p,
  #care_card_section .care_card.card_8 p {
    max-width: 180px;
  }

  #care_card_section .care_card.card_6 p,
  #care_card_section .care_card.card_9 p {
    max-width: 146px;
  }

  #care_card_section .care_card.card_9 img {
    max-width: 220px;
  }

  #care_count_section .care_count_anchor {
    font-size: 13px;
    line-height: 16px;
  }

  #care_count_section .care_ul li {
    font-size: 14px;
  }

  #resource_hero_section .res_hero_main {
    /* padding: 79px 0; */
    padding: 0;
  }

  #resource_hero_section .res_para_1 {
    font-size: 22px;
  }

  #resource_hero_section .res_para_2 {
    font-size: 56px;
    max-width: 450px;
    line-height: 71px;
  }

  #resource_hero_section .res_hero_img {
    max-width: 151px;
  }

  #resource_main_section .res_link_wrapper {
    /* padding: 60px 0; */
    padding: 20px 0;
  }

  #resource_main_section .resource_link {
    font-size: 28px;
  }


  /* caregiver Insights page */

  #careInsight_hero_section .careInsight_para_1 {
    font-size: 22px;
    line-height: 43px;
  }

  #careInsight_hero_section .careInsight_para_2 {
    font-size: 56px;
    line-height: 71px;
    max-width: 450px;
  }

  #careInsight_hero_section .careInsights_img {
    max-width: 160px;
  }

  #careInsight_hero_description .description_para p {
    font-size: 22px;
    line-height: 33px;
  }

  #careInsight_main_section .careInsightMain_outer .overlay_bg {
    background: linear-gradient(90deg,
        #fef9f1 48.7%,
        rgba(254, 249, 241, 0.804043) 57.06%,
        rgba(254, 249, 241, 0.562865) 66.76%,
        rgba(254, 249, 241, 0) 96.12%);
  }

  /* Article section */

  #article_hero_section .article_para_1 {
    font-size: 22px;
  }

  #article_hero_section .article_para_2 {
    font-size: 56px;
    line-height: 71px;
    max-width: 615px;
  }


  #article_hero_section .wrapper .overlay_bg {
    background: linear-gradient(90deg,
        #391a52 39.75%,
        rgba(57, 26, 82, 0) 94.15%);
  }

  #article_hero_description .description_para {
    font-size: 22px;
    line-height: 33px;
  }

  #article_main_section .articleMain_header {
    margin-bottom: 50px;
  }

  #article_main_section .articleMain_left .tcare_team img {
    max-width: 45px;
  }

  #article_main_section .articleMain_left .tcare_team p {
    font-size: 22px;
    line-height: 38px;
    padding-left: 15px;
  }

  #article_main_section .articleMain_right h2 {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 0;
  }

  #article_main_section .articleMain_right .para_1 {
    font-size: 20px;
    line-height: 30px;
  }

  #article_main_section .articleMain_content p {
    font-size: 20px;
    line-height: 30px;
  }

  .moreInsights_header {
    font-size: 35px;
    line-height: 48px;
    padding-bottom: 27px;
  }

  #careInsightCard_section {
    padding-bottom: 59px;
  }

  /* Contact page */

  #contact_section {
    padding: 40px 0;
  }

  #contact_section .contact_us {
    padding-left: 70px;
  }

  #contact_section .contact_hero_main {
    padding: 50px 70px 60px 70px;
  }

  #contact_section img.chat {
    min-width: 120px;
    max-width: 120px;
  }

  #contact_section .contact_form_outer {
    padding-left: 150px;
  }

  #contact_section .contact_form .submit_btn {
    min-width: 300px;
  }

  #fullscreenModal .modal-content {
    border-radius: 40px;
  }

  #fullscreenModal .fullscreen_outer img.qrCodeLarge {
    max-width: 250px;
  }

  #fullscreenModal .go_mobile_para {
    line-height: 36px;
  }

  footer .copyRight_row .social_media {
    margin-top: 20px;
    margin-left: 10px;
  }


  /* For GTL */

  #gtl_hero_section .hero_div {
    /* padding: 79px 0; */
    padding: 0;
  }

  #gtl_hero_section .hero_div .hero_left .hero_para_1 {
    font-size: 22px;
    max-width: 320px;
  }

  #gtl_hero_section .hero_div .hero_left .hero_para_2 {
    font-size: 56px;
    line-height: 71px;
    max-width: 450px;
  }

  #gtl_hero_section .hero_div img {
    /* width: 225px; */
    /* right: -36px; */
    width: 150%;
  }

  #hero_description .description_para.gtl {
    font-size: 21px;
    line-height: 33px;
    padding: 0;
  }

  .my_card_1.gtl {
    max-width: 303px;
  }

  #rec_cash_hero_section .hero_main {
    /* padding: 79px 0; */
    padding: 0;
  }

  #rec_cash_hero_section .para_1 {
    font-size: 22px;
  }

  #rec_cash_hero_section .para_2 {
    max-width: 450px;
    font-size: 56px;
    line-height: 71px;
    height: auto;
  }

  #rec_cash_hero_section .hero_img {
    max-width: 280px;
  }

  #gtl_rec_cash_main_section .cash_link_wrapper {
    /* padding: 60px 0; */
    padding: 20px 0;
  }

  #gtl_rec_cash_main_section .cash_link {
    font-size: 28px;
    margin-bottom: 0;
  }

  #gtl_rec_cash_main_section iframe {
    max-width: 500px;
    margin: 0;
  }

  /* ******** For Reframe financial ******* */
  #reframe_hero_section .hero_div .hero_left .hero_para_1 {
    font-size: 22px;
  }

  #reframe_hero_section .hero_div .hero_left .hero_para_2 {
    font-size: 56px;
    line-height: 71px;
    /* max-width: 450px; */
  }

  #hero_description.reframe .description_para {
    font-size: 21px;
    line-height: 40px;
  }

  #caregiver_hero_section.reframefinancial .care_para_2 {
    font-size: 56px;
    line-height: 70px;
    max-width: 320px;
  }

  #care_card_section.reframefinancial .care_card.card_7 img {
    top: -100%;
  }

  #care_card_section.reframefinancial .care_card.card_9 img {
    max-width: 200px;
  }

  /* resources page */

  #resource_hero_section.reframefinancial .res_para_2 {
    font-size: 56px;
    line-height: 71px;
  }

  /* Contact Page */

  #contact_section.reframefinancial .contact_hero_main {
    padding-right: 0px;
    padding-left: 0px;
  }

  #contact_section.reframefinancial .contact_heading {
    padding-top: 0;
    font-size: 40px;
  }

  /* Careinsights page */
  #careInsight_hero_section.reframefinancial .careInsight_para_2 {
    font-size: 56px;
    line-height: 71px;
    max-width: 450px;
  }

  #careInsight_hero_section.reframefinancial .careInsights_img {
    max-width: 300px;
  }

  /* Article page */
  #article_hero_section.reframefinancial .article_para_2 {
    font-size: 56px;
    line-height: 71px;
    max-width: 627px;
  }

  #article_hero_section.reframefinancial .wrapper .overlay_bg {
    background: linear-gradient(90deg,
        var(--reframeDarkBlue) 39.75%,
        rgba(57, 26, 82, 0) 94.15%);
  }
}

@media screen and (max-width: 767px) {

  .my_navbar {
    height: auto;
  }

  #hero_section .hero_div {
    /* padding: 40px 0 100px 0; */
    padding: 0;
  }

  #hero_section .hero_div img {
    /* width: 250px; */
    right: -36px;
  }

  #hero_section .hero_div .hero_left {
    padding-bottom: 50px;
  }

  #hero_section .hero_div .hero_left .hero_para_1 {
    max-width: 280px;
    line-height: 33px;
    font-size: 20px;
  }

  #hero_section .hero_div .hero_left .hero_para_2 {
    max-width: 300px;
  }

  #started_section {
    padding: 40px 0;
  }

  #started_section .main_card_1 {
    margin-bottom: 85px;
  }

  #started_section .started_row_2 {
    margin-bottom: 0;
  }

  #caregiver_hero_section .care_hero_main {
    /* padding: 70px 0; */
    padding: 0;
  }

  #caregiver_hero_section .care_para_1 {
    font-size: 20px;
    line-height: 33px;
    max-width: 280px;
  }

  #care_card_section .care_card_row {
    margin-bottom: 0;
  }

  #care_card_section .care_card {
    margin-bottom: 20px;
  }

  #care_card_section .care_card.card_3 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  #care_card_section .care_card.card_7 img {
    left: -140px;
  }

  #care_count_section .care_count_para {
    text-align: center;
  }

  #care_count_section .care_count_anchor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  #care_count_section .care_ul {
    margin-left: auto;
    margin-right: auto;
    max-width: 320px !important;
  }

  #resource_hero_section .res_hero_main {
    /* padding: 70px 0; */
    padding: 0;
  }

  #resource_hero_section .res_hero_img {
    /* top: 98px; */
    /* max-width: 162px; */
    max-width: 151px;
  }

  #resource_hero_section .res_para_1 {
    font-size: 20px;
    max-width: 280px;
    line-height: 33px;
  }

  #resource_main_section .resource_link {
    font-size: 25px;
  }

  #careInsight_hero_section .careInsight_para_1 {
    font-size: 20px;
    line-height: 33px;
    max-width: 280px;
  }

  #careInsight_hero_section .careInsight_para_2 {
    max-width: 450px;
    font-size: 56px;
    line-height: 71px;
  }

  #careInsight_main_section {
    padding-top: 49px;
    padding-bottom: 10px;
  }

  #careInsightCard_section {
    padding-top: 49px;
    padding-bottom: 59px;
  }

  #careInsightCard_section .careInsightCard {
    margin-bottom: 20px;
  }

  #careInsight_main_section .careInsightMain_outer .overlay_bg {
    /* background: linear-gradient(90deg,
        #fef9f1 30.7%,
        rgba(254, 249, 241, 0.804043) 45.06%,
        rgba(254, 249, 241, 0.562865) 56.76%,
        rgba(254, 249, 241, 0) 74.12%); */
    display: none;
  }

  #careInsight_main_section .careInsightMain_left {
    order: 2;
    padding: 20px;
  }

  #careInsight_main_section .careInsightMain_left h2 {
    font-size: 30px;
    line-height: 40px;
  }

  #careInsight_main_section .careInsightMain_right {
    position: static;
    order: 1;
    margin: 0 auto;
    width: -webkit-fill-available;
    max-height: 300px;
    height: 300px;
  }

  #careInsight_main_section .careInsightMain_right img {
    width: 100%;
    border-radius: 45px 45px 0 0;
    -webkit-border-radius: 45px 45px 0 0;
    -moz-border-radius: 45px 45px 0 0;
    -ms-border-radius: 45px 45px 0 0;
    -o-border-radius: 45px 45px 0 0;
    height: 100%;
    object-fit: cover;

  }


  /* Article section */

  #article_hero_section .article_para_1 {
    font-size: 20px;
    line-height: 33px;
    max-width: 280px;
  }

  #article_hero_section .article_para_2 {
    /* padding-bottom: 141px; */
    max-width: 450px;
  }

  #article_hero_section .wrapper {
    background-position: right;
    background-size: auto 100%;
  }

  #article_hero_section .wrapper .overlay_bg {
    background: linear-gradient(90deg,
        #391a52 19.75%,
        rgba(57, 26, 82, 0) 94.15%);
  }

  #contact_section .contact_us {
    padding-left: 10px;
  }

  #contact_section .contact_heading {
    font-size: 30px;
    line-height: 42px;
  }

  #contact_section .contact_hero_main {
    flex-direction: row-reverse;
    padding: 50px 10px 60px 10px;
  }

  #contact_section .contact_form_outer {
    padding-left: 20px;
    padding-right: 20px;
  }

  #contact_section .contact_form {
    width: 100%;
    max-width: 100%;
  }

  /* 
  #contact_section .contact_form .my_select {
    max-width: 100%;
  } */
  #contact_section .contact_form .submit_btn {
    min-width: auto;
  }

  #go_mobile_section {
    padding: 50px 0;
    height: auto;
  }

  #fullscreenModal .modal-content {
    border-radius: 30px;
  }

  #fullscreenModal .modal-content .modal-body {
    padding: 20px;
  }

  #fullscreenModal .fullscreen_left {
    margin-right: 15px;
  }

  #fullscreenModal .fullscreen_outer img.qrCodeLarge {
    max-width: 230px;
  }

  #fullscreenModal .go_mobile_para {
    font-size: 20px;
    max-width: 200px;
    line-height: 26px;
  }

  /* footer {
    height: auto;
    padding: 50px 0;
  } */

  /* for GTL */
  #gtl_hero_section .hero_div {
    /* padding: 40px 0 100px 0; */
    padding: 0;
  }

  #gtl_hero_section .hero_div img {
    /* width: 250px; */
    right: -36px;
  }

  #gtl_hero_section .hero_div .hero_left {
    padding-bottom: 150px;
  }

  #gtl_hero_section .hero_div .hero_left .hero_para_1 {
    max-width: 280px;
    line-height: 33px;
    font-size: 20px;
  }

  #gtl_hero_section .hero_div .hero_left .hero_para_2 {
    max-width: 300px;
  }

  #rec_cash_hero_section .hero_main {
    /* padding: 70px 0; */
    padding: 0;
  }

  #rec_cash_hero_section .para_1 {
    font-size: 20px;
    line-height: 33px;
    max-width: 280px;
  }

  #rec_cash_hero_section .hero_img {
    max-width: 200px;
  }

  /* ******** For Reframe financial ******* */
  #reframe_hero_section .hero_div .hero_left .hero_para_1 {
    font-size: 20px;
    max-width: 280px;
    line-height: 33px;
  }

  #reframe_hero_section .hero_div .hero_left .hero_para_2 {
    max-width: 320px;
  }

  #reframe_hero_section .hero_div img.reframeHeroFamilyImg {
    right: 10px;
    top: 100px;
    max-width: 295px;
  }

  #started_section.reframe .started_row_2 {
    margin-bottom: 26px;
  }

  #care_card_section.reframefinancial .care_card.card_7 img {
    top: -100%;
    left: -20%;
    max-width: 220px;
  }

  /* Resources Page */
  #resource_main_section.reframefinancial .resource_link img.screener_icon,
  #resource_main_section.reframefinancial .resource_link img.connect_icon,
  #resource_main_section.reframefinancial .resource_link img.community_icon,
  #resource_main_section.reframefinancial .resource_link img.other_benefits_icon {
    max-width: 50px;
  }

  #resource_main_section.reframefinancial .res_main_para {
    padding: 10px 0 42px 72px;
  }

  /* Contact Page */

  #contact_header_section.reframefinancial {
    height: auto;
  }

  #contact_section.reframefinancial .contact_hero_main img.chat {
    min-width: 280px;
    max-width: 280px;
  }

  #contact_section.reframefinancial .contact_heading {
    padding-top: 40px;
    font-size: 30px;
    line-height: 50px;
  }

  /* Article Page */
  #article_hero_section.reframefinancial .article_para_2 {
    max-width: 450px;
  }

  #article_hero_section.reframefinancial .wrapper .overlay_bg {
    background: linear-gradient(90deg,
        var(--reframeDarkBlue) 19.75%,
        rgba(57, 26, 82, 0) 94.15%);
  }
}

@media screen and (max-width: 670px) {

  /* For GTL */
  #gtl_care_card_section {
    max-height: 1440px;
  }

  #gtl_care_card_section .left {
    width: -webkit-fill-available;
    position: relative;
    height: 450px;
  }

  #gtl_care_card_section .left p {
    margin: 0 auto;
  }

  #gtl_care_card_section .left img {
    position: absolute;
    right: -65px;
    bottom: 0;
    left: auto;
    top: auto;
  }

  #gtl_care_card_section .right {
    flex-direction: column;
    align-items: center;
    width: 500px;
    left: -6%;
  }

  #gtl_care_card_section .right .my_card.card_1,
  #gtl_care_card_section .right .my_card.card_2,
  #gtl_care_card_section .right .my_card.card_3,
  #gtl_care_card_section .right .my_card.card_4,
  #gtl_care_card_section .right .my_card.card_5,
  #gtl_care_card_section .right .my_card.card_6 {
    left: auto;
    top: auto;
    right: auto;
  }

  #gtl_care_card_section .right .my_card.card_1 {
    order: 2;
    left: -12%;
    top: -8.7%;
  }

  #gtl_care_card_section .right .my_card.card_2 {
    order: 1;
    /* right: -26%; */
    right: -125px;
  }

  #gtl_care_card_section .right .my_card.card_3 {
    order: 5;
    top: -34.5%;
    /* right: -25%; */
    right: -125px;
  }

  #gtl_care_card_section .right .my_card.card_4 {
    order: 3;
    /* right: -25.6%; */
    top: -17.3%;
    right: -125px;
  }

  #gtl_care_card_section .right .my_card.card_5 {
    order: 4;
    top: -25.9%;
    left: -12.4%;
  }

  #gtl_care_card_section .right .my_card.card_6 {
    order: 6;
    top: -43.3%;
    left: -12.9%;
  }

  #gtl_care_card_section .right .my_card.card_3 img,
  #gtl_care_card_section .right .my_card.card_5 img {
    display: none;
  }

  #gtl_care_card_section .right .my_card.card_1 img,
  #gtl_care_card_section .right .my_card.card_6 img {
    display: block;
    max-width: 400px;
    position: absolute;
  }

  #gtl_care_card_section .right .my_card.card_1 img {
    max-width: 320px;
    left: -23%;
    top: -97%;
    z-index: 1;
  }

  #gtl_care_card_section .right .my_card.card_6 img {
    max-width: 210px;
    left: 65%;
    bottom: -20%;
  }

  /* ******** For Reframe financial ******* */

  #reframe_hero_section .hero_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 51px 0;
    height: fit-content;
  }

  #reframe_hero_section .hero_div .hero_left {
    width: 100%;
    align-items: center;
  }

  #reframe_hero_section .hero_div .hero_right {
    width: 100%;
  }

  #reframe_hero_section .hero_div img.reframeHeroFamilyImg {
    position: static;
    width: auto;
    padding-top: 40px;
  }

  #hero_description.reframe .description_para {
    font-size: 19px;
    line-height: 33px;
    text-align: center;
  }

  #caregiver_hero_section.reframefinancial .care_hero_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 51px 0;
    height: auto;
  }

  #caregiver_hero_section.reframefinancial .care_hero_main .care_hero_img {
    /* order: 3; */
    position: static;
    max-width: 250px;
  }

  #care_description.reframefinancial .description_para {
    text-align: center;
  }

  #care_card_section.reframefinancial .care_card.card_9 img {
    max-width: 180px;
  }

  /* Resources Page */

  #resource_hero_section.reframefinancial .res_hero_main {
    /* padding: 51px 0 80px 0; */
    padding: 51px 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  #resource_hero_section.reframefinancial .res_hero_img {
    position: static;
    margin-left: auto;
    margin-right: auto;
    /* height: 180px; */
    /* max-width: 121px; */
  }

  /* Conatct Page */
  #contact_section.reframefinancial .contact_hero_main {
    flex-direction: column;
  }

  #contact_section.reframefinancial .contact_heading {
    font-size: 30px;
    line-height: 42px;
  }

  /* CareInsights Page */
  #careInsight_hero_section.reframefinancial .careInsight_hero_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 51px 0;
    height: auto;
  }

  #careInsight_hero_section.reframefinancial .careInsights_img {
    position: static;
  }

  /* Article Page */

  #article_hero_section.reframefinancial .article_hero_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 71px 0;
    height: 100%;
  }
}

@media screen and (max-width: 576px) {
  .container {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  /* .my_navbar {
    padding: 17px 0;
  } */

  /* .navbar .navbar-brand img {
    width: 135px;
  } */


  #hero_section {
    background: linear-gradient(to right,
        var(--darkPurple) 0%,
        var(--darkPurple) 90%,
        var(--mint) 90%,
        var(--mint) 100%);
  }

  #hero_section .hero_div img {
    /* width: 180px;
    width: 168px; */
    right: -27px;
  }

  #care_card_section .care_card.card_9 img {
    right: -35%;
  }

  #care_card_section .care_card.card_3 {
    margin-bottom: 20px;
  }

  #care_count_section .care_count_para {
    text-align: center;
  }

  #resource_hero_section .res_hero_main {
    /* padding: 40px 0 100px 0; */
    padding: 0;
  }

  #resource_hero_section .res_para_2 {
    max-width: 337px;
  }

  #resource_hero_section .res_hero_img {
    top: 118px;
    max-width: 121px;
  }

  /* Article section */

  #article_hero_section .wrapper {
    background-position: center;
    background-size: auto 100%;
  }

  #article_hero_section .wrapper .overlay_bg {
    background: linear-gradient(90deg,
        #391a52 17.75%,
        rgba(57, 26, 82, 0) 134.15%);
  }

  footer {
    overflow: hidden;
  }

  footer .footerLogo {
    max-width: 130px;
  }

  /* *****For GTL **** */

  #hero_description .description_para.gtl {
    padding: 20px 0;
    height: auto;
  }

  #started_section .main_card_1.gtl {
    margin-bottom: 50px;
  }

  .my_card_1.gtl {
    max-width: 230px;
    background-size: contain;
    margin-bottom: 0;
    max-height: 260px;
  }

  .my_card_1.gtl p {
    font-size: 24px;
    max-width: 167px;
    line-height: 32.78px;
  }

  .my_card_1 span.gtl {
    width: 61px;
    height: 61px;
    top: 25px;
    left: -105%;
  }

  #rec_cash_hero_section .hero_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 51px 0;
    height: auto;
  }

  #rec_cash_hero_section .hero_main .para_2 {
    order: 3;
    font-size: 52px;
    line-height: 64px;
    max-width: 410px;
  }

  #rec_cash_hero_section .hero_main .para_1 {
    order: 2;
    font-size: 20px;
    line-height: 118%;
  }

  #rec_cash_hero_section .hero_main .hero_img {
    order: 1;
    position: static;
    margin-bottom: 20px;
    /* max-width: 141px; */
  }

  #gtl_rec_cash_main_section iframe {
    max-width: 400px;
    max-height: 250px;
    margin: 0;
  }

  /* ******** For Reframe Financial ********* */
  #started_section.reframe {
    padding-bottom: 0;
  }

  .my_card_1.reframe {
    height: 150px;
  }

  #care_card_section.reframefinancial .care_card.card_7 img {
    top: -80%;
    left: -38%;
    max-width: 200px;
  }

  #resource_hero_section.reframefinancial .res_para_2 {
    max-width: 450px;
  }

  /* Article Page */
  #article_hero_section.reframefinancial .wrapper .overlay_bg {
    background: linear-gradient(90deg,
        var(--reframeDarkBlue) 0%,
        rgba(57, 26, 82, 0) 125.15%);
  }
}

/*@media screen and (max-width:480px) {
  .hero_div img {
    width: 34%;
    right: -16px;
  }

  .hero_div .hero_para_2 {
    max-width: 290px;
    font-size: 56px;
    line-height: 71px;
  }

  #hero_description .description_para {
    font-size: 16px;
    line-height: 28px;
    padding: 30px 0;
    text-align: justify;
  }

  #started_section .started_heading {
    font-size: 30px;
  }

  #started_section .started_row_1 {
    padding-bottom: 70px;
  }

  .my_card_1 p {
    font-size: 22px;
  }


  #caregiver_hero_section .care_hero_img {
    max-width: 141px;
  }

  #care_card_section .care_card.card_3 img {
    right: -65px;
  }

  #care_count_section .care_count_para {
    font-size: 26px;
  }


  #resource_hero_section .res_hero_main {
    padding: 40px 0 80px 0;
  }

  #resource_hero_section .res_para_1 {
    font-size: 22px;

  }

  #resource_hero_section .res_para_2 {
    font-size: 56px;
    line-height: 58px;
  }

  #resource_hero_section .res_hero_img {
    top: 118px;
    max-width: 121px;
  }

  #resource_main_section .resource_link {
    font-size: 22px;
  }

  #resource_main_section .res_link_wrapper {
    padding: 30px 0;
  }

  #contact_section {
    padding: 35px 0;
  }

  #contact_section .contact_hero_main {
    flex-direction: column;
    padding: 30px 10px 60px 10px;
  }

  #contact_section img.chat {
    margin-bottom: 10px;
  }

  #contact_section .contact_form .submit_btn {
    min-width: 100%;
  }

  #go_mobile_section .go_mobile_para {
    font-size: 14px;
    padding-bottom: 0;
  }

  #go_mobile_section .qrCodeSmall {
    width: 130px;
  }

  #go_mobile_section .qrCode_div {
    margin-left: 30px !important;
  }

  #fullscreenModal .modal-dialog {
    margin-left: auto;
    margin-right: auto;
  }

  #fullscreenModal .fullscreen_outer {
    flex-direction: column;
    align-items: center;
  }

  #fullscreenModal .fullscreen_left {
    order: 2;
  }

  #fullscreenModal .fullscreen_left .go_mobile_para {
    font-size: 18px;
    max-width: 250px;
    line-height: 26px;
    padding-bottom: 0;
  }

  #fullscreenModal .fullscreen_outer img.qrCodeLarge {
    max-width: 250px;
  }

}

 @media screen and (max-width:425px) {
  #hero_section .hero_div {
    padding: 60px 0 100px 0;
  }

  .hero_div img {
    width: 20%;
  }

  .hero_div .hero_para_1 {
    max-width: 210px;
    line-height: 33px;
    font-size: 20px;
  }

  .hero_div .hero_para_2 {
    max-width: 290px;
    font-size: 56px;
    line-height: 65px;
  }

  #caregiver_hero_section .care_hero_main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
  }

  #caregiver_hero_section .care_para_1 {
    order: 2;
    max-width: 250px;
    padding-top: 20px;
  }

  #caregiver_hero_section .care_para_2 {
    order: 3;
  }

  #caregiver_hero_section .care_hero_img {
    position: static;
    order: 1;
    margin-left: 0;
  }

  #resource_hero_section .res_hero_main {
    display: flex;
    flex-direction: column;
  }

  #resource_hero_section .res_para_1 {
    max-width: 250px;
    order: 2;
  }

  #resource_hero_section .res_para_2 {
    order: 3;
  }

  #resource_hero_section .res_hero_img {
    position: static;
    order: 1;
    margin-right: auto;
    padding-bottom: 20px;
  }

  #go_mobile_section .go_mobile_left .go_mobile_para {
    line-height: 18px;
    max-width: 200px;
  }

  #go_mobile_section .go_mobile_left .download_app {
    margin-top: 10px;
    align-self: center;
  }

  #go_mobile_section .qrCodeSmall {
    width: 115px;
  }

  #go_mobile_section .qrCode_div {
    margin-left: 10px !important;
  }

  #go_mobile_section .qrCode_div a {
    margin-left: 5px !important;
  }

  footer .copyRight_para {
    line-height: 25px;
    display: flex;
    flex-direction: column;
  }

  footer .copyRight_para a {
    margin-left: 0;
  }
}

@media screen and (max-width:391px) {

  #care_card_section .care_card.card_3 {
    margin-bottom: 20px;
  }

  #care_card_section .care_card.card_3 img {
    right: -40px;
  }

  #care_card_section .care_card.card_7 img {
    left: -110px;
  }

  #care_card_section .care_card.card_7 img {
    top: -75%;
    width: 270px;
  }
}

@media screen and (max-width:320px) {
  #go_mobile_section .qrCodeSmall {
    width: 80px;
  }

  #go_mobile_section .qrCode_div a {
    margin-left: 1px !important;
  }
} */