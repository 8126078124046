:root {
  --white: #ffffff;
  --purple: #611c73;
  --lavendar: #d494fb;
  --periwinkle: #b6caff;
  --aqua: #b9e4f2;
  --mint: #d6f9ea;
  --ruby: #fe4672;
  --darkPurple: #391a52;
  --cream: #fef9f1;
  --primaryCream: #f8f0ec;
  --secondaryCream: #fef0eb;
  --darkPurple-40: #afa0b1;
  --purple-60: #a075a5;

  /* ****** For Reframe Financial ******** */
  --reframeWhite: #ffffff;
  --reframePrimaryBlue: #5ee5de;
  --reframeSecondaryBlue: #e6f5fe;
  --reframeCultured: #f8f8f8;
  --reframeDarkBlue: #0c0244;
  --reframeDarkBlue-60: #140e44;
  --reframeGreen: #62bab1;
  --reframeBlue: #0085c8;
  --reframeRed: #ac2184;
  --reframeYellow: #ee9d37;
  --reframePurple: #5e3b91;
  --reframeGrey: #B0B0BB;

}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Manrope";
  /* overflow: overlay; */
  background: var(--cream);
}

section,
.container,
nav,
footer {
  min-width: 355px !important;
}

/* body::-webkit-scrollbar {} */

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1200px;
}

.my_navbar button.navbar-toggler {
  border: none;
}

.my_navbar button.navbar-toggler:focus {
  box-shadow: none;
}

p {
  margin: 0;
}

.my_animation {
  opacity: 0.1;
  transform: scale3d(0.7, 0.7, 0.7);
  transform-style: preserve-3d;
  transition: all 1s;
}

.my_animation.loaded {
  opacity: 1;
  /* transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); */
  transform: scale3d(1, 1, 1);
}

.my_navbar {
  /* height: 132px; */
  /* padding: 45px 0 37px 0; */
  /* padding: 40px 0; */
  padding: 25px 0;
  background: var(--cream);
  width: 100% !important;
}

.my_navbar .navbar-brand.coBrand {
  margin-right: auto;
}

.my_navbar .navbar-brand img {
  max-width: 166px;
  max-height: 40px;
  object-fit: contain;
}

/* .my_navbar .navbar-brand .coBrandLogo {
  max-width: 124px;
} */

.my_navbar ul li {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  border: 1px solid var(--cream);
  border-radius: 25px;
  /* margin-right: 24px; */
  /* height: 50px; */
  /* padding: 15px 25px; */
  min-width: fit-content;
}

.my_navbar ul li.dropdown a.dropdown-toggle {
  min-width: 237px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.my_navbar ul li a {
  color: var(--purple) !important;
  letter-spacing: 0.02em;
  /*height: 50px;
  */
  line-height: 40px;
  font-size: 15px;
  padding: 4px 25px !important;
  border-radius: 25px;
  display: block;
  align-items: center;
  font-weight: 600;
}

.my_navbar ul li a.dropdown-toggle .dropdown_arrow {
  margin-left: 5px;
  width: 24px;
}

.my_navbar ul li a:hover {
  background: var(--primaryCream) !important;
}

.my_navbar ul li a.dropdown-toggle::after {
  display: none;
}

.my_navbar ul.dropdown-menu {
  border-radius: 25px;
  padding: 0;
  background: var(--cream);
  width: 100%;
  margin-top: -2px;
}

.my_navbar ul.dropdown-menu li {
  margin: 0;
}

.my_navbar ul.dropdown-menu li a.dropdown-item:active {
  background: var(--purple);
  /* color: var(--white) !important; */
}

.my_navbar ul.dropdown-menu li a {
  padding-left: 13px !important;
}

.my_navbar ul .signUpBtn {
  background-color: var(--purple) !important;
  color: var(--cream) !important;
}

.my_navbar ul .signInBtn {
  background-color: var(--purple) !important;
  color: var(--cream) !important;
}

.my_navbar ul .signInBtn:hover,
.my_navbar ul .signUpBtn:hover {
  background-color: var(--ruby) !important;
}

.download_app {
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  color: var(--cream);
  letter-spacing: 0.05em;
  min-width: 191px;
  /* height: 50px; */
  background: var(--purple);
  border-radius: 38px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 25px;
  padding: 6px 25px 8px 25px;
}

.download_app:hover {
  background: var(--ruby);
}

.download_app .download_span_1 {
  font-size: 11px;
  text-transform: uppercase;
  color: var(--cream);
  line-height: 18px;
}

.download_app .download_span_2 {
  font-size: 16px;
  color: var(--cream);
  line-height: 18px;
}

.download_app .cloud_download {
  margin-left: 13px;
}

.loginBtn {
  width: 104px;
  height: 50px;
  display: flex;
  justify-content: center;
  padding: 15px 25px;
  background-color: var(--purple);
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: var(--cream);
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  text-decoration: none;
  margin-left: 25px;
}

.loginBtn:hover {
  background-color: var(--ruby);
  color: var(--cream);
}

/* **************** Hero section ***************** */
#hero_section {
  /* background: var(--darkPurple); */
  background: linear-gradient(to right,
      var(--darkPurple) 0%,
      var(--darkPurple) 76%,
      var(--mint) 60%,
      var(--mint) 100%);
  overflow: hidden;
}

/* #hero_section .wrapper {
  
  background-image: url("../Images/Vector.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
} */

/* #hero_section .wrapper{
  max-width: 1920px;
  margin: 0 auto;
  background: url('../Images/vector.png');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
} */

#hero_section .hero_div {
  /* padding: 161px 0; */
  /* padding: 79px 0; */
  /* padding: 167px 0 190px 0; */
  /* position: relative; */
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  height: 482px;
  flex-direction: row;
}

#hero_section .hero_div .hero_left {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#hero_section .hero_div .hero_right {
  width: 35%;
  position: relative;
}

#hero_section .hero_div .hero_left .hero_para_1 {
  max-width: 726px;
  padding: 5px 0;
  margin-bottom: 13px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;

  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--ruby);

  z-index: 1;
}

#hero_section .hero_div .hero_left .hero_para_2 {
  max-width: 731px;
  height: 164px;
  font-style: normal;
  font-weight: 300;
  font-size: 86px;
  line-height: 89px;

  color: var(--white);

  z-index: 1;
}

#hero_section .hero_div img {
  position: absolute;
  height: 100%;
  right: -300px;
  top: 0;
  width: 150%;

  /* display: none; */
}

/* ************** Hero description ************** */

#hero_description {
  /* height: 314px; */
  background-color: var(--primaryCream);
}

#hero_description .description_para {
  max-width: 969px;
  /* padding: 93px 0; */
  /* padding: 85px 0; */
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 41px;
  color: var(--darkPurple);
  height: 252px;
  display: flex;
  align-items: center;
}

#hero_description .description_para.coBrand {
  max-width: 1159px;
  height: auto;
  padding: 63px 0;
}

/* ***************** Started Section *************** */

#started_section {
  background-color: var(--cream);
  padding-top: 49px;
  /* padding-bottom: 222px; */
  padding-bottom: 93px;
}

#started_section .started_container {
  max-width: 992px;
}

#started_section .started_row_1 {
  padding-bottom: 104px;
}

#started_section .started_heading {
  font-weight: 400;
  font-size: 48px;
  line-height: 40px;
  color: var(--ruby);
  padding: 19px 0;
  margin: 0;
}

.my_card_1 {
  background-color: var(--secondaryCream);
  max-width: 328px;
  height: 230px;
  border-radius: 31px;
  /* padding: 72px 66px 59px 67px; */
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my_card_1 p {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: var(--darkPurple);
  max-width: 190px;
}

.my_card_1 span {
  position: absolute;
  background-color: var(--ruby);
  width: 71px;
  height: 71px;
  /* padding: 19px; */
  border-radius: 50%;
  top: -33px;
  /* left: 133px; */
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_card_1 a {
  padding: 15px 25px;
  background-color: var(--purple);
  /*border: 1px solid #FEF9F1;
  */
  border-radius: 25px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: var(--cream);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

#started_section .started_row_2 {
  /* margin-bottom: 126px; */
  margin-bottom: 97px;
}

#started_section .started_link:hover {
  background: var(--ruby);
}

/* ************** Caregiver Insights Section ************** */
#careInsight_hero_section {
  background-color: var(--darkPurple);
}

#careInsight_hero_section.gtl {
  background: url(../Images/gtl_banner.png) no-repeat;
  background-size: cover;
  overflow: hidden;
}

#careInsight_hero_section.gtl .wrapper {
  background: linear-gradient(270deg,
      var(--darkPurple) 0%,
      rgba(57, 26, 82) 45%,
      rgba(57, 26, 82, 0.38) 81.53%);
}

#careInsight_hero_section .careInsight_hero_main {
  position: relative;
  /* padding: 167px 0 190px 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 482px;
}

#careInsight_hero_section .careInsight_para_1 {
  max-width: 726px;
  padding: 5px 0;
  margin-bottom: 13px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;

  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--ruby);
}

#careInsight_hero_section .careInsight_para_2 {
  max-width: 731px;
  font-style: normal;
  font-weight: 300;
  font-size: 86px;
  line-height: 89px;
  color: var(--white);
  height: 164px;
  /* padding: 3px 0; */
}

#careInsight_hero_section .careInsights_img {
  position: absolute;
  right: 0;
  /* top: 36%; */
  /* max-width: 254px; */
  max-width: 237px;
  top: 0;
  bottom: 0;
  margin: auto;
}

#careInsight_hero_description {
  background-color: var(--purple);
}

#careInsight_hero_description .description_para {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 93px 0; */
  padding: 82px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: var(--cream);
}

#careInsight_hero_description .description_para p {
  max-width: 729px;
  padding-left: 40px;
}

#careInsight_main_section {
  background: var(--darkPurple);
  padding-top: 99px;
  padding-bottom: 40px;
}

#careInsight_main_section .careInsightMain_outer {
  background-color: var(--secondaryCream);
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  color: var(--darkPurple);
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  position: relative;
}

#careInsight_main_section .careInsightMain_outer .overlay_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(90deg,
      #fef9f1 56.7%,
      rgba(254, 249, 241, 0.804043) 71.06%,
      rgba(254, 249, 241, 0.562865) 78.76%,
      rgba(254, 249, 241, 0) 88.12%);
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  z-index: 2;
}

#careInsight_main_section .careInsightMain_right {
  margin-left: auto;
  z-index: 1;
}

#careInsight_main_section .careInsightMain_right img {
  /*padding-right: 45px;
  */
  width: 100%;
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
}

#careInsight_main_section .careInsightMain_left {
  max-width: 600px;
  padding: 72px 0 61px 87px;
  /* padding-left: 87px; */
  z-index: 3;
}

#careInsight_main_section .careInsightMain_left h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 47px;
  margin-bottom: 12px;
  color: var(--ruby);
}

#careInsight_main_section .careInsightMain_left p {
  font-family: "Newsreader";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  padding: 0px 0 43px 0;
}

.readmore {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 15px 22px 15px 25px; */
  gap: 12px;
  color: var(--cream);
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  max-width: 159px;
  height: 50px;
  background-color: var(--purple);
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.readmore span {
  display: flex;
}

.readmore:hover {
  background-color: var(--ruby);
  color: var(--cream);
  /* text-decoration: underline; */
}

#careInsightCard_section {
  padding-top: 59px;
  padding-bottom: 99px;
  background-color: var(--darkPurple);
}

#careInsightCard_section .careInsightCard {
  max-width: 348px;
}

/* #careInsightCard_section .careInsightCard_head {
  background-color: var(--secondaryCream);
} */

#careInsightCard_section .careInsightCard_head img {
  width: 100%;
  max-height: 166px;
  object-fit: cover;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

#careInsightCard_section .careInsightCard_body {
  margin-top: -10px;
  min-height: 222px;
}

#careInsightCard_section .careInsightCard_body:hover {
  background-color: var(--purple);
  border-radius: 0 0 20px 20px;
  -webkit-border-radius: 0 0 20px 20px;
  -moz-border-radius: 0 0 20px 20px;
  -ms-border-radius: 0 0 20px 20px;
  -o-border-radius: 0 0 20px 20px;
}

#careInsightCard_section .careInsightCard_body:hover .readmore {
  background-color: var(--ruby);
}

#careInsightCard_section .careInsightCardlink {
  display: block;
  text-decoration: none;
  color: var(--darkPurple);
  padding-left: 20px;
  padding-bottom: 40px;
  min-height: 200px;
}

#careInsightCard_section .careInsightCard_body p {
  max-width: 291px;
  padding: 30px 0;
  font-family: "Newsreader";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 36px;
  color: var(--cream);
}

#careInsightCard_section .articleNextBtn,
#careInsightCard_section .articlePrevBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--cream);
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  background-color: var(--purple);
  max-width: 159px;
  height: 50px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  margin-top: 50px;
}

#careInsightCard_section .articleNextBtn:hover,
#careInsightCard_section .articlePrevBtn:hover {
  background-color: var(--ruby);
  color: var(--cream);
}

/* ************** CareIdentityHeroSection ************** */

#article_hero_section {
  background-color: var(--darkPurple);
  /* height: 526px; */
}

#article_hero_section .wrapper {
  position: relative;
  max-width: 1920px;
  /* height: 526px; */
  margin: 0 auto;
  /* background: url("../Images/howCaregiving_large.jpeg"); */
  background-position: right;
  /* background-size: 48% 100%; */
  background-size: contain;
  background-repeat: no-repeat;
}

#article_hero_section .article_hero_main {
  position: relative;
  /* padding: 167px 0 190px 0; */
  /* height: 526px; */
  height: 482px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#article_hero_section .wrapper .overlay_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(90deg,
      #391a52 48.45%,
      rgba(57, 26, 82, 0) 83.15%); */
  /* background: linear-gradient(90deg,
      #391a52 59.45%,
      rgba(57, 26, 82, 0) 83.15%); */

  /* background: linear-gradient(90deg,
      #391a52 58.75%,
      rgba(57, 26, 82, 0) 83.15%); */
  background: linear-gradient(90deg,
      #391a52 62.75%,
      rgba(57, 26, 82, 0) 94.15%);
}

#article_hero_section .article_para_1 {
  max-width: 726px;
  padding: 5px 0;
  margin-bottom: 13px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;

  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--ruby);
}

#article_hero_section .article_para_2 {
  max-width: 916px;
  font-style: normal;
  font-weight: 300;
  font-size: 86px;
  line-height: 90px;
  color: var(--white);
  height: 164px;
  /* padding: 3px 0; */
}

#article_hero_description {
  /* height: 314px; */
  background-color: var(--purple);
}

#article_hero_description .description_para {
  max-width: 969px;
  /* padding: 93px 0; */
  padding: 72px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 43px;
  color: var(--cream);
}

#article_main_section {
  padding-top: 112px;
  padding-bottom: 161px;
}

#article_main_section .articleMain_outer {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

#article_main_section .articleMain_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  color: var(--darkPurple);
  justify-content: space-between;
  margin-bottom: 75px;
}

#article_main_section .articleMain_content p {
  font-family: "Newsreader";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  color: var(--darkPurple);
  padding-bottom: 23px;
}

#article_main_section .articleMain_content .sub_heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: var(--purple);
  padding-bottom: 10px;
}

#article_main_section .articleMain_content .small_heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: var(--purple);
  padding-bottom: 10px;
}

#article_main_section .articleMain_content .large_heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: var(--purple);
  padding-bottom: 10px;
}

#article_main_section .articleMain_left .tcare_team {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#article_main_section .articleMain_left .tcare_team p {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--ruby);
  padding-left: 23px;
  padding-bottom: 0;
}

#article_main_section .articleMain_right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#article_main_section .articleMain_right h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: var(--purple-60);
  text-align: right;
}

#article_main_section .articleMain_right .para_1 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--ruby);
  text-align: right;
}

.moreInsights_header {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 34.9662px;
  line-height: 48px;
  text-align: center;
  color: var(--ruby);
  padding-bottom: 27px;
}

/* ************** Contact Section ************** */
#contact_section {
  background: var(--darkPurple);
  padding: 60px 0;
}

#contact_section .contact_us {
  color: var(--ruby);
  font-size: 16px;
  line-height: 20px;
}

#contact_section .contact_hero_main {
  padding-top: 55px;
  padding-bottom: 100px;
  display: flex;
  align-items: flex-start;
  grid-column-gap: 40px;
  padding-left: 8px;
}

#contact_section img.chat {
  min-width: 220px;
  max-width: 220px;
}

#contact_section .contact_heading {
  color: var(--white);
  font-size: 54px;
  line-height: 66px;
  font-weight: 300;
  /* max-width: 700px; */
}

#contact_section .contact_form_outer {
  padding-left: 248px;
  padding-top: 22px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#contact_section .contact_form {
  max-width: 400px;
}

#contact_section .contact_form .my_input,
#contact_section .contact_form .my_select {
  border: none;
  display: flex;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: var(--darkPurple);
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
}

#contact_section .contact_form .input_text {
  margin-right: 30px;
  margin-bottom: 30px;
  padding-top: 21px;
  padding-bottom: 21px;
  padding-left: 31px;
}

#contact_section .contact_form .my_select {
  height: 44px;
  /*max-width: 500px;
  */
  margin-bottom: 30px;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 31px;
  border-radius: 32px;
  color: var(--darkPurple);
  cursor: pointer;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
}

#contact_section .contact_form .my_textarea {
  min-height: 160px;
  padding: 12px 14px;
  margin-bottom: 30px;
  /*color: rgb(194, 191, 191);
  */
  resize: none;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

#contact_section .contact_form .submit_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 10px;
  padding: 13px 35px;
  border: 1px solid transparent;
  border-radius: 40px;
  background-color: var(--ruby);
  color: var(--cream);
  font-size: 18px;
}

#contact_section .contact_form .submit_btn:hover {
  background-color: var(--purple);
}

#contact_section .contact_form ::-webkit-input-placeholder {
  color: rgb(182, 179, 179);
}

/* **************Go_mobile_section************** */

#go_mobile_section {
  background-color: var(--primaryCream);
  /* padding: 90px 0 91px 0; */
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 252px;
}

#go_mobile_section .go_mobile_left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

#go_mobile_section .go_mobile_para {
  text-align: right;
  max-width: 307px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: var(--purple);
  /* padding-bottom: 22px; */
}

#fullscreenModal .modal-dialog {
  max-width: fit-content;
}

#fullscreenModal .modal-content {
  border-radius: 78px;
}

#fullscreenModal .modal-content .modal-body {
  padding: 64px 69px 77px 58px;
}

#fullscreenModal .fullscreen_outer img.qrCodeLarge {
  max-width: 400px;
}

#fullscreenModal .fullscreen_outer {
  display: flex;
  flex-direction: row;
}

#fullscreenModal .fullscreen_left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 56px;
  padding-top: 28px;
  align-items: self-end;
}

#fullscreenModal .go_mobile_para {
  font-size: 32px;
  line-height: 44px;
  max-width: 342px;
}

/* ************** Footer section **************** */

footer {
  background-color: var(--purple);
  /* padding-top: 66px;
  padding-bottom: 73px; */
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 250px;
}

footer .footerLogo {
  max-width: 166px;
  max-height: 38px;
}

footer .copyRight_row {
  padding-top: 40px;
}

footer .copyRight_para {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--white);
}

footer .copyRight_para a {
  color: var(--white);
  margin-left: 5px;
}

footer .copyRight_para a:hover {
  color: var(--aqua);
}

footer .copyRight_row .linkedIn {
  margin-left: 10px;
}

/* ************* Resources *************** */

/* ************* Resources Hero Section *************** */
#resource_hero_section {
  background-color: var(--darkPurple);
  /* padding-top: 112px; */
}

#resource_hero_section .wrapper {
  /* height: 587px; */
  /* background: url("../Images/res_hero_bg.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#resource_hero_section .res_hero_main {
  /* display: flex;
  flex-direction: column;
  min-height: 587px;
  justify-content: center; */
  /* padding: 169px 0; */
  position: relative;
  /* padding: 167px 0 190px 0; */
  padding: 0;
  height: 482px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#resource_hero_section .res_para_1 {
  max-width: 726px;
  padding: 5px 0;
  margin-bottom: 13px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;

  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--ruby);
}

#resource_hero_section .res_para_2 {
  max-width: 731px;
  font-style: normal;
  font-weight: 300;
  font-size: 86px;
  line-height: 89px;
  color: var(--white);
  height: 164px;
  /* padding: 3px 0; */
}

#resource_hero_section .res_hero_img {
  position: absolute;
  right: -63px;
  /* top: 113px; */
  /* max-width: 261px; */
  max-width: 225px;
  bottom: 0;
}

#resource_main_section {
  background: var(--cream);
}

#resource_main_section .res_link_wrapper {
  /* padding: 103px 0; */
  padding: 43px 0;
  padding-bottom: 0;
}

#resource_main_section .resource_link {
  font-style: normal;
  font-weight: 400;
  font-size: 34.9662px;
  line-height: 48px;
  padding: 9px 0;
  /* margin-bottom: 16px; */
}

#resource_main_section .resource_link a {
  color: var(--ruby);
}

#resource_main_section .res_main_para {
  max-width: 966px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--darkPurple);
  padding: 10px 0 42px 62px;
}

#resource_main_section .res_main_para a {
  color: var(--ruby);
}

.my_iframe {
  width: 100%;
  height: 100vh !important;
  overflow: hidden;
}

/* ******** caregiver_hero_section ************* */

#caregiver_hero_section {
  background: var(--darkPurple);
}

#caregiver_hero_section .care_hero_main {
  position: relative;
  /* padding: 167px 0 190px 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 482px;
}

#caregiver_hero_section .care_para_1 {
  max-width: 726px;
  padding: 5px 0;
  margin-bottom: 13px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;

  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--ruby);
}

#caregiver_hero_section .care_para_2 {
  max-width: 731px;
  font-style: normal;
  font-weight: 300;
  font-size: 86px;
  line-height: 89px;
  color: var(--white);
  height: 164px;
  /* padding: 3px 0; */
}

#caregiver_hero_section .care_hero_img {
  position: absolute;
  right: 0;
  /* top: 36%; */
  max-width: 302px;
  top: 0;
  bottom: 0;
  margin: auto;
  max-height: 234px;
  z-index: 2;
}

/* *********** care_description ***************/

#care_description {
  background-color: var(--primaryCream);
}

#care_description .description_para {
  /* padding: 99px 0; */
  padding: 72px 0;
  max-width: 612px;
}

#care_description .description_para p {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 45px;
}

#care_description .description_para a {
  padding: 15px 25px;
  background: var(--purple);
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: var(--cream);
  /* border: 1px solid var(--cream); */
  border-radius: 25px;
  text-decoration: none;
}

#care_description .description_para a:hover {
  background: var(--ruby);
}

/* ********** care_card_section **************** */

#care_card_section {
  background: var(--cream);
  padding: 136px 0;
}

#care_card_section .care_card_outer {
  width: 79%;
}

#care_card_section .care_card_row {
  margin-bottom: 34px;
}

#care_card_section .care_card {
  max-width: 276px;
  height: 181px;
  border-radius: 31px;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

#care_card_section .care_card.card_1 p {
  max-width: 164px;
}

#care_card_section .care_card.card_2 p,
#care_card_section .care_card.card_5 p {
  max-width: 172px;
}

#care_card_section .care_card.card_3 p {
  max-width: 194px;
}

#care_card_section .care_card.card_4 p,
#care_card_section .care_card.card_7 p,
#care_card_section .care_card.card_8 p {
  max-width: 217px;
}

#care_card_section .care_card.card_6 p,
#care_card_section .care_card.card_9 p {
  max-width: 208px;
}

#care_card_section .care_card p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: var(--purple);
}

#care_card_section .care_card.card_3 img {
  position: absolute;
  right: -38%;
  top: -43%;
}

#care_card_section .care_card.card_7 img {
  position: absolute;
  top: -100%;
  left: -85%;
}

#care_card_section .care_card.card_9 img {
  position: absolute;
  bottom: -53%;
  right: -45%;
}

/* ************ care_count_section ***************** */

#care_count_section {
  background: var(--purple);
  /* padding: 70px 0; */
  padding: 61px 0;
}

#care_count_section .care_count_para {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 48px;
  color: var(--cream);
  padding: 9px 0 26px 0;
}

#care_count_section .care_count_anchor {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--purple);
  background: var(--aqua);
  padding: 15px 15px 15px 20px;
  height: 54px;
  border-radius: 25px;
  margin-bottom: 18px;
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
}

#care_count_section .care_count_anchor:hover,
#care_count_section .care_count_anchor[aria-expanded="true"] {
  background: var(--purple);
  color: var(--ruby);
  border: 1px solid var(--ruby);
}

#care_count_section .care_count_anchor:hover img,
#care_count_section .care_count_anchor[aria-expanded="true"] img {
  content: url("../Images/chevron-up.svg");
}

#care_count_section .care_ul {
  max-width: 359px;
  margin: 0;
}

#care_count_section .care_ul li {
  font-style: normal;
  font-weight: 500;
  font-size: 17.4831px;
  line-height: 21px;
  color: var(--white);
  margin-bottom: 6px;
}

#care_count_section #care_ul_1 {
  max-width: 359px;
}

#care_count_section #care_ul_2 {
  max-width: 354px;
}

#care_count_section #care_ul_3 {
  max-width: 254px;
}

/* ********App Download ******** */

#app_download_section {
  background-color: var(--cream);
}

#app_download_section nav {
  background-color: var(--purple);
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#app_download_section nav img {
  max-width: 150px;
}

#app_download_section .app_container {
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

#app_download_section .app_container .play_store,
#app_download_section .app_container .app_store {
  background-color: purple;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  height: 60px;
  text-decoration: none;
  padding-left: 20px;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
}

#app_download_section .app_container .play_store:hover,
#app_download_section .app_container .app_store:hover {
  background-color: var(--ruby);
}

#app_download_section .app_container .play_store img,
#app_download_section .app_container .app_store img {
  width: 25px;
  height: 25px;
}

#app_download_section .app_container .play_store .right,
#app_download_section .app_container .app_store .right {
  margin-left: 10px;
}

#app_download_section .app_container .play_store .right .para_1,
#app_download_section .app_container .app_store .right .para_1 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--cream);
}

#app_download_section .app_container .play_store .right .para_2,
#app_download_section .app_container .app_store .right .para_2 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: var(--cream);
}

/* ************************************ */

/* For GTL custom co-brand */

#gtl_hero_section {
  background: url(../Images/gtl_banner.png) no-repeat;
  background-size: cover;
  overflow: hidden;
}

#gtl_hero_section .wrapper {
  /* background: linear-gradient(270deg,
      #391a52 0%,
      rgba(57, 26, 82, 0.8) 35%,
      rgba(57, 26, 82, 0.38) 81.53%); */
  background: linear-gradient(270deg,
      #25377d 0%,
      rgba(57, 26, 82) 50%,
      rgba(57, 26, 82, 0.38) 81.53%);
}

#gtl_hero_section .hero_div {
  display: flex;
  height: 482px;
  flex-direction: row;
}

#gtl_hero_section .hero_div {
  display: flex;
  height: 482px;
  flex-direction: row;
}

#gtl_hero_section .hero_div .hero_left {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#gtl_hero_section .hero_div .hero_right {
  width: 35%;
  position: relative;
}

#gtl_hero_section .hero_div .hero_left .hero_para_1 {
  max-width: 726px;
  padding: 5px 0;
  margin-bottom: 13px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--ruby);
  z-index: 1;
}

#gtl_hero_section .hero_div .hero_left .hero_para_2 {
  max-width: 731px;
  height: 164px;
  font-style: normal;
  font-weight: 300;
  font-size: 86px;
  line-height: 89px;
  color: var(--white);
  z-index: 1;
}

#gtl_hero_section .hero_div img {
  position: absolute;
  height: 100%;
  right: -300px;
  top: 0;
  width: 180%;
}

#hero_description .description_para.gtl {
  max-width: 1120px;
  height: auto;
  padding: 63px 0;
}

.my_card_1.gtl {
  background: url("../Images/polygon_secondaryCream.svg") center no-repeat;
  max-width: 328px;
  height: 280px;
  border-radius: 31px;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my_card_1 span.gtl {
  position: absolute;
  background: url("../Images/polygon_ruby.svg") center no-repeat;
  background-size: contain;
  width: 71px;
  height: 71px;
  top: 13px;
  left: -95%;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ** GTL caregiver_hero_section ** */

#caregiver_hero_section.gtl {
  background: url("../Images/gtl_banner.png") no-repeat center;
  background-size: cover;
}

#caregiver_hero_section.gtl .wrapper {
  /* background: linear-gradient(270deg,
      #391a52 0%,
      rgba(57, 26, 82, 0.8) 55%,
      rgba(57, 26, 82, 0.38) 81.53%); */

  background: linear-gradient(270deg,
      var(--darkPurple) 0%,
      rgba(57, 26, 82) 45%,
      rgba(57, 26, 82, 0.38) 81.53%);
}

#care_description .description_para.gtl {
  max-width: 750px;
}

#gtl_care_card_section {
  background-color: var(--cream);
  overflow: hidden;
}

#gtl_care_card_section .outer {
  display: flex;
  padding-top: 78px;
  padding-bottom: 59px;
}

#gtl_care_card_section .left p {
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 52px;
  text-transform: capitalize;
  color: var(--ruby);
  margin-bottom: 66px;
}

#gtl_care_card_section .left img {
  max-width: 550px;
  position: relative;
  left: -20%;
}

#gtl_care_card_section .right {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  left: -13%;
}

#gtl_care_card_section .right .my_card {
  width: 257px;
  height: 257px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../Images/polygon_white.svg") center no-repeat;
}

#gtl_care_card_section .right .my_card p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: var(--purple);
  max-width: 172px;
}

#gtl_care_card_section .right .my_card img {
  max-width: 400px;
  position: absolute;
}

#gtl_care_card_section .right .my_card.card_1 img,
#gtl_care_card_section .right .my_card.card_6 img {
  display: none;
}

#gtl_care_card_section .right .my_card.card_1 {
  top: 17%;
  right: -5%;
}

#gtl_care_card_section .right .my_card.card_2 {
  right: -2%;
}

#gtl_care_card_section .right .my_card.card_3 {
  right: -81%;
  top: -16%;
}

#gtl_care_card_section .right .my_card.card_3 img {
  right: -1%;
  top: -53%;
}

#gtl_care_card_section .right .my_card.card_4 {
  right: -2%;
}

#gtl_care_card_section .right .my_card.card_5 {
  left: 5%;
  top: -17%;
}

#gtl_care_card_section .right .my_card.card_5 img {
  left: 43%;
  top: 27%;
}

#gtl_care_card_section .right .my_card.card_6 {
  right: -40%;
  top: -17%;
}

#resource_hero_section.gtl {
  background: url("../Images/gtl_banner.png") no-repeat center;
  background-size: cover;
}

#resource_hero_section.gtl .wrapper {
  background: linear-gradient(270deg,
      var(--darkPurple) 0%,
      rgba(57, 26, 82) 45%,
      rgba(57, 26, 82, 0.38) 81.53%);
}

/* recover cash */

#rec_cash_hero_section {
  background: url("../Images/gtl_banner.png") no-repeat center;
  background-size: cover;
}

#rec_cash_hero_section .wrapper {
  background: linear-gradient(270deg,
      var(--darkPurple) 0%,
      rgba(57, 26, 82) 45%,
      rgba(57, 26, 82, 0.38) 81.53%);
}

#rec_cash_hero_section .hero_main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 482px;
}

#rec_cash_hero_section .para_1 {
  max-width: 726px;
  padding: 5px 0;
  margin-bottom: 13px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;

  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--ruby);
}

#rec_cash_hero_section .para_2 {
  max-width: 800px;
  font-style: normal;
  font-weight: 300;
  font-size: 86px;
  line-height: 89px;
  color: var(--white);
  height: 164px;
}

#rec_cash_hero_section .hero_img {
  position: absolute;
  right: 0;
  max-width: 302px;
  top: 0;
  bottom: 0;
  margin: auto;
  max-height: 234px;
}

/* rec main section */
#gtl_rec_cash_main_section {
  background-color: var(--cream);
}

#gtl_rec_cash_main_section .cash_link_wrapper {
  padding: 53px 0 122px 0;
  display: flex;
  flex-direction: column;
}

#gtl_rec_cash_main_section .cash_link {
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 48px;
  padding: 9px 0;
  color: var(--ruby);
  margin-bottom: 25px;
  width: fit-content;
}

#gtl_rec_cash_main_section iframe {
  margin: 25px 0;
}

#gtl_rec_cash_main_section .res_main_para {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--darkPurple);
  max-width: 945px;
  margin-top: 25px;
}

/* ************************************ */

/* For Reframe Financial custom co-brand */
.my_navbar.reframe {
  background: var(--reframeWhite);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.my_navbar.reframe ul li {
  border: 1px solid var(--reframeWhite);
}

.my_navbar.reframe ul li a {
  color: var(--reframeDarkBlue-60) !important;
  font-weight: 700;
}

.my_navbar.reframe ul li a:hover {
  background: var(--reframeSecondaryBlue) !important;
}

.my_navbar.reframe ul.dropdown-menu {
  background: var(--reframeWhite);
}

.my_navbar.reframe ul li a.dropdown-toggle .dropdown_arrow {
  filter: brightness(0);
  -webkit-filter: brightness(0);
}

.my_navbar.reframe ul .signUpBtn,
.my_navbar.reframe ul .signInBtn {
  background-color: var(--reframePrimaryBlue) !important;
  color: var(--reframeDarkBlue) !important;
  font-family: "Open Sans";
  font-weight: 700;
}

.my_navbar.reframe ul .signUpBtn:hover,
.my_navbar.reframe ul .signInBtn:hover {
  background-color: var(--reframeBlue) !important;
  color: var(--reframeWhite) !important;
}

.download_app.reframefinancial {
  background: var(--reframeDarkBlue);
}

.download_app.reframefinancial:hover {
  background: var(--reframeBlue);
}

/* reframe_hero_section */

#reframe_hero_section {
  background-color: var(--reframeWhite);
  overflow: clip;
  background: url("../Images/reframeBanner.svg") no-repeat;
  background-position: top right;
}

#reframe_hero_section .hero_div {
  display: flex;
  height: 482px;
  flex-direction: row;
}

#reframe_hero_section .hero_div .hero_left {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#reframe_hero_section .hero_div .hero_right {
  width: 35%;
  position: relative;
}

#reframe_hero_section .hero_div .hero_left .hero_para_1 {
  max-width: 726px;
  padding: 5px 0;
  margin-bottom: 13px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 43px;

  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--reframeGreen);

  z-index: 1;
}

#reframe_hero_section .hero_div img {
  position: absolute;
  /* height: 100%; */
  right: -300px;
  top: 0;
  width: 195%;

  /* display: none; */
}

#reframe_hero_section .hero_div img.reframeHeroFamilyImg {
  position: absolute;
  /* height: 100%; */
  width: auto;
  right: 20px;
  top: 90px;
  max-width: 324px;
}

#reframe_hero_section .hero_div .hero_left .hero_para_2 {
  max-width: 731px;
  height: 164px;
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 86px;

  color: var(--reframeDarkBlue-60);

  z-index: 1;
}

/* ************** Hero description ************** */

#hero_description.reframe {
  background-color: var(--reframeWhite);
}

#hero_description.reframe .description_para {
  color: var(--reframeDarkBlue);
  font-weight: 700;
  line-height: 45px;
  max-width: 100%;
  text-align: left;
  font-size: 24px;
}

/* ***************** Started Section *************** */

#started_section.reframe {
  background-color: var(--reframeWhite);
  background: url("../Images/reframeCurveBg.png") no-repeat;
  background-position: top;
  background-size: contain;
}

#started_section.reframe .started_heading {
  font-weight: 700;
  color: var(--reframeDarkBlue);
  padding: 40px 0;
}

.my_card_1.reframe {
  background-color: transparent;
  margin-bottom: 0px;
  height: 200px;
}

.main_card_1.reframe p {
  font-weight: 700;
  color: var(--reframeDarkBlue);
  max-width: fit-content;
  margin-top: 20px;
}

.my_card_1.reframe span.reframeHeart {
  background-color: var(--reframeRed);
}

.my_card_1.reframe span.reframeHand {
  background-color: var(--reframeYellow);
}

.my_card_1.reframe span.reframeHome {
  background-color: var(--reframeBlue);
}

.my_card_1.reframe span.reframeEducation {
  background-color: var(--reframePurple);
}

.main_card_1.reframe a {
  background-color: var(--reframePrimaryBlue);
  color: var(--reframeDarkBlue);
  font-weight: 700;
}

#started_section.reframe .started_row_2 {
  margin-bottom: 126px;
}

#started_section.reframe .started_link:hover {
  background: var(--reframeBlue);
  color: var(--reframeWhite);
}

/* **************Reframe Go_mobile_section************** */

#go_mobile_section.reframefinancial {
  background-color: var(--reframeCultured);
}

#go_mobile_section.reframefinancial .go_mobile_para {
  color: var(--reframeDarkBlue);
  font-weight: 700;
}

#go_mobile_section.reframefinancial .qrCode_div .fullscreen_btn_lg,
#go_mobile_section.reframefinancial .go_mobile_left .fullscreen_btn_mob {
  filter: brightness(0);
  -webkit-filter: brightness(0);
}

/* **************Reframe Footer section **************** */

footer.reframefinancial {
  background-color: var(--reframeDarkBlue);
}

/* ******** Reframe caregiver_hero_section ************* */

#caregiver_hero_section.reframefinancial {
  /* background-color: var(--reframeWhite); */
  overflow: clip;
  background: url("../Images/reframeBanner.svg") no-repeat 100% 0%;
}

#caregiver_hero_section.reframefinancial img.reframeBanner {
  top: 0;
  right: -300px;
  position: absolute;
  max-width: 68%;
}

#caregiver_hero_section.reframefinancial .care_hero_img {
  max-height: 316px;
  max-width: 281px;
}

#caregiver_hero_section.reframefinancial .care_para_1 {
  color: var(--reframeGreen);
  font-weight: 700;
}

#caregiver_hero_section.reframefinancial .care_para_2 {
  color: var(--reframeDarkBlue-60);
  font-weight: 700;
  font-size: 70px;
  max-width: 676px;
}

/* ***********Reframe care_description ***************/

#care_description.reframefinancial {
  /* background-color: var(--reframeWhite); */
  background-color: transparent;
}

#care_description.reframefinancial .description_para {
  max-width: 882px;
  position: relative;
}

#care_description.reframefinancial .description_para p {
  color: var(--reframeDarkBlue);
  font-weight: 700;
}

#care_description.reframefinancial .description_para a {
  background-color: var(--reframePrimaryBlue);
  color: var(--reframeDarkBlue);
  font-weight: 700;
}

#care_description.reframefinancial .description_para a:hover {
  background: var(--reframeBlue);
  color: var(--reframeWhite);
}

#care_description.reframefinancial .description_para .reframeSideBanner {
  position: absolute;
  z-index: 2;
  right: 112%;
  top: 0%;
}

/* **********Reframe care_card_section **************** */
#care_card_combined_section.reframefinancial {
  background: url("../Images/reframeSideBanner.svg") no-repeat;
  background-size: 300px;
  /* background-position: 0 70px; */
}

#care_card_section.reframefinancial {
  /* background: var(--reframeWhite); */
  background-color: transparent;
}

#care_card_section.reframefinancial .care_card {
  background: var(--reframeCultured);
}

#care_card_section.reframefinancial .care_card p {
  color: var(--reframeDarkBlue);
}

#care_card_section.reframefinancial .care_card.card_7 img {
  left: -55%;
}

/* ************ Reframe care_count_section ***************** */

#care_count_section.reframefinancial {
  background: var(--reframeSecondaryBlue);
}

#care_count_section.reframefinancial .care_count_para {
  color: var(--reframeDarkBlue);
  font-weight: 700;
}

#care_count_section.reframefinancial .care_count_anchor {
  background: var(--reframeBlue);
  color: var(--reframeWhite);
}

#care_count_section.reframefinancial .care_count_anchor img {
  content: url("../Images/reframe-chevron-down.svg");
}

#care_count_section.reframefinancial .care_count_anchor:hover,
#care_count_section.reframefinancial .care_count_anchor[aria-expanded="true"] {
  background: transparent;
  color: var(--reframeBlue);
  border: 1px solid var(--reframeBlue);
}

#care_count_section.reframefinancial .care_count_anchor:hover img,
#care_count_section.reframefinancial .care_count_anchor[aria-expanded="true"] img {
  content: url("../Images/reframe-chevron-up.svg");
}

#care_count_section.reframefinancial .care_ul li {
  color: var(--reframeDarkBlue);
  font-weight: 400;
}

/* ************* Reframe Resources *************** */

/* *************Reframe Resources Hero Section *************** */
#resource_hero_section.reframefinancial {
  background-color: var(--reframeWhite);
  background: url("../Images/reframeBanner.svg") no-repeat 100% 0%;
}

#resource_hero_section.reframefinancial .res_para_1 {
  color: var(--reframeGreen);
  font-weight: 700;
}

#resource_hero_section.reframefinancial .res_para_2 {
  color: var(--reframeDarkBlue);
  font-weight: 700;
  font-size: 70px;
}

#resource_hero_section.reframefinancial img.reframeBanner {
  top: 0;
  right: -300px;
  position: absolute;
  max-width: 68%;
}

#resource_hero_section.reframefinancial .res_hero_img {
  top: 30%;
  max-width: 268px;
  right: 50px;
}

/* ***********Reframe Res Main section *********** */

#resource_main_section.reframefinancial {
  background: var(--reframeWhite);
  background: url("../Images/reframeSideBanner.svg") no-repeat 0 0;
  background-size: 300px;
}

#resource_main_section.reframefinancial .resource_link {
  font-weight: 700;
}

#resource_main_section.reframefinancial .resource_link a {
  color: var(--reframeDarkBlue);
}

#resource_main_section.reframefinancial .resource_link img.screener_icon {
  content: url("../Images/reframeResHeart.png");
}

#resource_main_section.reframefinancial .resource_link img.connect_icon {
  content: url("../Images/reframeResHome.png");
  margin-right: 20px !important;
}

#resource_main_section.reframefinancial .resource_link img.community_icon {
  content: url("../Images/reframeResHand.png");
}

#resource_main_section.reframefinancial .resource_link img.other_benefits_icon {
  content: url("../Images/reframeResEduction.png");
}

#resource_main_section.reframefinancial .res_main_para {
  color: var(--reframeDarkBlue);
  padding: 10px 0 42px 82px;
}

/* **************Reframe Caregiver Insights Section ************** */
#combined_careInsight_hero_description.reframefinancial {
  background: url("../Images/reframeSideBanner.svg") no-repeat;
  background-size: 300px;
}

#careInsight_hero_section.reframefinancial {
  background-color: var(--reframeWhite);
  background: url("../Images/reframeBanner.svg") no-repeat 100% 0%;
}

#careInsight_hero_section.reframefinancial .careInsight_para_1 {
  font-weight: 700;
  color: var(--reframeGreen);
}

#careInsight_hero_section.reframefinancial .careInsight_para_2 {
  font-weight: 700;
  color: var(--reframeDarkBlue);
  font-size: 70px;
  max-width: 650px;
}

#careInsight_hero_description.reframefinancial {
  background-color: transparent;
}

#careInsight_hero_section.reframefinancial .careInsights_img {
  max-width: 342px;
}

#careInsight_hero_description.reframefinancial .description_para {
  color: var(--reframeDarkBlue);
  font-weight: 700;
}

#careInsight_hero_description.reframefinancial .description_para p {
  max-width: 792px;
}

#careInsight_main_section.reframefinancial {
  background-color: transparent;
}

#careInsight_main_section.reframefinancial .careInsightMain_outer {
  background-color: var(--reframeDarkBlue);
  color: var(--reframeWhite);
}

#careInsight_main_section.reframefinancial .careInsightMain_outer .overlay_bg {
  background: linear-gradient(90deg,
      #0c0244 57.04%,
      rgba(12, 2, 68, 0.55814) 66.67%,
      rgba(12, 2, 68, 0) 100%);
}

#careInsight_main_section.reframefinancial .careInsightMain_left {
  max-width: 624px;
}

#careInsight_main_section.reframefinancial .careInsightMain_left h2 {
  color: var(--reframeWhite);
  font-weight: 700;
}

#careInsight_main_section.reframefinancial .careInsightMain_left p {
  font-family: "Open Sans";
  font-weight: 400;
}

#careInsight_main_section.reframefinancial .readmore {
  color: var(--reframeDarkBlue);
  background-color: var(--reframePrimaryBlue);
  font-weight: 700;
}

#careInsight_main_section.reframefinancial .readmore:hover {
  color: var(--reframeWhite);
  background-color: var(--reframeBlue);
}

#careInsight_main_section.reframefinancial .readmore img {
  content: url('../Images/reframeReadmore.svg');
}

#careInsight_main_section.reframefinancial .readmore:hover img {
  content: url('../Images/readmore.svg');
}

#careInsightCard_section.reframefinancial {
  background-color: var(--reframeWhite);
}

#careInsightCard_section.reframefinancial.addBg {
  background-color: var(--reframeSecondaryBlue);
}

#careInsightCard_section.reframefinancial .readmore {
  color: var(--reframeDarkBlue);
  background-color: var(--reframePrimaryBlue);
  font-weight: 700;
}

#careInsightCard_section.reframefinancial .readmore:hover {
  color: var(--reframeWhite);
  background-color: var(--reframeBlue);
}

#careInsightCard_section.reframefinancial .readmore img {
  content: url('../Images/reframeReadmore.svg');
}

#careInsightCard_section.reframefinancial .readmore:hover img {
  content: url('../Images/readmore.svg');
}

#careInsightCard_section.reframefinancial .careInsightCard_body:hover {
  background-color: var(--reframeSecondaryBlue);
}

#careInsightCard_section.reframefinancial.addBg .careInsightCard_body:hover {
  background-color: var(--reframeWhite);
}

#careInsightCard_section.reframefinancial .careInsightCard_body:hover .readmore {
  background-color: var(--reframeBlue);
  color: var(--reframeWhite);
}

#careInsightCard_section.reframefinancial .careInsightCard_body:hover .readmore img {
  content: url('../Images/readmore.svg');
}

#careInsightCard_section.reframefinancial .careInsightCard_body p {
  max-width: 326px;
  font-size: 24px;
  color: var(--reframeDarkBlue);
  font-family: "Open Sans";
  font-weight: 600;
}

#careInsightCard_section.reframefinancial .articleNextBtn,
#careInsightCard_section.reframefinancial .articlePrevBtn {
  background-color: var(--reframePrimaryBlue);
  color: var(--reframeDarkBlue);
  font-weight: 700;
}

#careInsightCard_section.reframefinancial .articleNextBtn:hover,
#careInsightCard_section.reframefinancial .articlePrevBtn:hover {
  background-color: var(--reframeBlue);
  color: var(--reframeWhite);
  font-weight: 700;
}

#careInsightCard_section.reframefinancial .moreInsights_header {
  font-family: 'Open Sans';
  color: var(--reframeDarkBlue);
  font-weight: 700;
}

/* **************Reframe CareIdentityHeroSection ************** */

#article_hero_section.reframefinancial {
  background-color: var(--reframeDarkBlue);
}

#article_hero_section.reframefinancial .wrapper .overlay_bg {
  background: linear-gradient(90deg,
      var(--reframeDarkBlue) 62.75%,
      rgba(57, 26, 82, 0) 94.15%);
}

#article_hero_section.reframefinancial .article_para_1 {
  color: var(--reframeGreen);
  font-weight: 700;
}

#article_hero_section.reframefinancial .article_para_2 {
  color: var(--reframeWhite);
  font-weight: 700;
  font-size: 70px;
  max-width: 787px;
}

#combined_article_main_section.reframefinancial {
  background: url("../Images/reframeSideBanner.svg") no-repeat;
  background-size: 300px;
}

#article_hero_description.reframefinancial {
  background-color: transparent;
}

#article_hero_description.reframefinancial .description_para {
  color: var(--reframeDarkBlue);
  font-weight: 700;
  max-width: 996px;
  line-height: 38px;
}

#article_main_section.reframefinancial .articleMain_content p {
  color: var(--reframeDarkBlue);
  font-family: "Open Sans";
  font-weight: 400;
}

#article_main_section.reframefinancial .articleMain_content .sub_heading {
  color: var(--reframeGreen);
  font-family: "Open Sans";
}

#article_main_section.reframefinancial .articleMain_content .small_heading {
  font-family: 'Open Sans';
  color: var(--reframeGreen);
  font-weight: 600;
}

#article_main_section.reframefinancial .articleMain_content .large_heading {
  color: var(--reframeGreen);
  font-family: "Open Sans";
}

#article_main_section.reframefinancial .articleMain_right h2 {
  color: var(--reframeGrey);
}

#article_main_section.reframefinancial .articleMain_right .para_1 {
  color: var(--reframeGreen);
}

/* **************Reframe Contact Section ************** */

#contact_section.reframefinancial {
  background-color: var(--reframeWhite);
  padding-top: 0;
  /* background: url("../Images/reframeBanner.svg") no-repeat 100% 0%; */
}

#contact_header_section.reframefinancial {
  background: url("../Images/reframeBanner.svg") no-repeat 100% 0%;
}

#contact_section.reframefinancial .contact_us {
  display: none;
}

#contact_section.reframefinancial .contact_hero_main {
  flex-direction: row-reverse;
  /* padding-top: 0; */
  justify-content: space-between;
}

#contact_section.reframefinancial .contact_hero_main img.chat {
  content: url('../Images/reframeContactHeroImg.svg');
  min-width: 300px;
  max-width: 324px;
}

#contact_section.reframefinancial .contact_heading {
  color: var(--reframeDarkBlue);
  font-weight: 700;
  font-size: 42px;
  line-height: 59px;
  padding-top: 55px;
  max-width: 700px;
}

#contact_inner_section.reframefinancial {
  background: url("../Images/reframeSideBanner.svg") no-repeat;
  background-size: 300px;
  padding-bottom: 60px;
}

#contact_section.reframefinancial .contact_form .my_input,
#contact_section.reframefinancial .contact_form .my_select {
  border: 1px solid var(--reframeGrey);
}

#contact_section.reframefinancial .contact_form .my_select {
  color: var(--reframeDarkBlue);
}

#contact_section.reframefinancial .contact_form .my_select option {
  background-color: var(--reframeSecondaryBlue);
}

#contact_section.reframefinancial .contact_form .my_input:focus,
#contact_section.reframefinancial .contact_form .my_select:focus {
  border: 1px solid var(--reframeSecondaryBlue);
  box-shadow: 0 0 0 0.1rem var(--reframePrimaryBlue);
}

#contact_section.reframefinancial .contact_form .submit_btn {
  background-color: var(--reframePrimaryBlue);
  color: var(--reframeDarkBlue);
  font-weight: 700;
}

#contact_section.reframefinancial .contact_form .submit_btn:hover {
  background-color: var(--reframeBlue);
  color: var(--reframeWhite);
}

#contact_section.reframefinancial .contact_form_outer:has(span) {
  padding-left: 20px;
  padding-right: 20px;
}

#contact_section.reframefinancial .contact_form_outer span {
  background-color: var(--reframeSecondaryBlue);
  color: var(--reframeDarkBlue);
}


/* ************************************ */